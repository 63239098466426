// used native fetching function to fetch different host

import { config } from 'config';
import i18n from 'core/translation/i18next/i18next';
import { Countries } from 'modules/common';
import { GeocoderSearchResult } from 'modules/onboarding/components/GeocoderPanel';
import { formatLocationDataFromApi } from 'utils';

export interface IFetchLocationQuery {
    postalCode?: string;
    placeName?: string;
    countryCode?: string;
}
export interface IFetchLocationQueryResult {
    lat: number;
    lng: number;
    placeName: string;
    postalCode: string;
    adminName1: string;
    adminName2?: string;
    countryCode: keyof typeof Countries;
}
/**
 * Fetching coordinates and district by postal code.
 *
 * @param postalCode postal code.
 * @param setResult callback after receiving the result.
 */
export const fetchLocationQuery = async ({
    postalCode,
    placeName,
    countryCode,
}: IFetchLocationQuery): Promise<IFetchLocationQueryResult[]> => {
    const fetchLocation = async () => {
        const response = await fetch(
            `https://secure.geonames.org/postalCodeSearchJSON?${postalCode ? `postalcode=${postalCode}&` : ''}${
                placeName ? `placename_startsWith=${placeName}&` : ''
            }${countryCode ? `country=${countryCode}&` : ''}maxRows=10&username=koala42`,
            { method: 'GET' },
        );

        if (response.status === 200) {
            const result: {
                postalCodes: IFetchLocationQueryResult[];
            } = await response.json();

            return result.postalCodes?.length ? result.postalCodes : [];
        }
        return [];
    };

    return fetchLocation();
};

interface IFetchReverseLocationQueryResponse {
    city: string;
    country: string;
    countryCode: keyof typeof Countries;
    postalCode: string;
    latitude: number;
    longitude: number;
}

/**
 * Fetching country, country code, region, city and street by coordinates
 *
 * @param coordinates provided coordinates.
 */

export const fetchReverseLocationQuery = async ({
    coordinates,
}: {
    coordinates: [latitude: number, longitude: number];
}): Promise<IFetchReverseLocationQueryResponse | null> => {
    // please note, response may vary depending on what are you looking for. For example, when you are looking for the whole country, you can't expect to get the street

    const fetchLocation = async () => {
        const response = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinates.reverse()}.json?limit=1&access_token=${
                config.api.mapboxToken
            }&language=${i18n.language}&types=address`,
            // 'https://api.mapbox.com/geocoding/v5/mapbox.places/14.4435535,50.1053307.json?limit=1&access_token=pk.eyJ1IjoiaHVsZGFjeiIsImEiOiJjbGJ6M2l3d2cwd2hyM3FvNXM5M2twb2RlIn0.nVPfoiec06LkIocNYRPoGA&language=cs&types=address',
            { method: 'GET' },
        );

        if (response.status !== 200) {
            return null;
        }
        const result: {
            type: 'FeatureCollection';
            features: GeocoderSearchResult[];
        } = await response.json();

        if (!result.features[0]) return null;

        return formatLocationDataFromApi(result.features[0]);
    };

    return fetchLocation();
};


export type IFetchRoutesQueryResponse = {
    routes: {
        weight_name: string;
        weight: number;
        duration: number;
        distance: number;
        geometry: {
            type: string;
            coordinates: [longitude: number, latitude: number][];
        };
    }[];
};
/**
 * Fetching routes by coordinates
 *
 * @param coordinates array of coordinates (from city, to city).
 */
export const fetchRoutesQuery = async (
    coordinates: [latitude: number, longitude: number][],
): Promise<IFetchRoutesQueryResponse | null> => {
    // please note, response may vary depending on what are you looking for. For example, when you are looking for the whole country, you can't expect to get the street

    const fetchLocation = async () => {
        const response = await fetch(
            `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates.join(
                ';',
            )}?geometries=geojson&overview=full&access_token=${config.api.mapboxToken}&language=${i18n.language}`,
            // docs: https://docs.mapbox.com/playground/directions/?coordinates=9.69054%2C52.459817&coordinates=14.421253%2C50.087465
            // GET request example: https://api.mapbox.com/directions/v5/mapbox/driving/9.660325,52.4139875;17.878811,48.659341?geometries=geojson&overview=full&access_token=pk.eyJ1IjoiaHVsZGFjeiIsImEiOiJjbGJ6M2l3d2cwd2hyM3FvNXM5M2twb2RlIn0.nVPfoiec06LkIocNYRPoGA
            { method: 'GET' },
        );

        if (response.status !== 200) {
            return null;
        }
        const result: IFetchRoutesQueryResponse = await response.json();

        return result;
    };

    return fetchLocation();
};
