export const translateMimeType = (mimeType: string) => {
    switch (mimeType) {
        case 'application/pdf':
            return 'PDF';
        case 'image/png':
            return 'PNG';
        case 'image/jpeg':
            return 'JPEG';
        case 'image/jpg':
            return 'JPG';
        default:
            return 'Unknown';
    }
};
