import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CommissionSchema, TCommissionUserSchema } from 'modules/commissions';

import styles from './CommissionInformationHeader.module.scss';

const getUserLabel = (user?: Partial<TCommissionUserSchema | null>) => {
    if (!user) {
        return '---';
    }
    const { name, surname, number } = user;

    if (name === surname) {
        return `${name} (${number})`;
    }

    return `${name} ${surname} (${number})`;
};

export const CommissionInformationHeader: React.FC = () => {
    const { t } = useTranslation();
    const { control } = useFormContext<CommissionSchema>();

    const { week, year, number, relation, addedBy, carrierAssignedBy } = useWatch({
        control,
    });

    return (
        <div className={styles.commissionInfoWrapper}>
            <div>
                {t('commissions.form.qaplineId.addedBy')}:{' '}
                <span className={styles.commissionInfoHeaderValue}>{getUserLabel(addedBy)}</span>
            </div>
            <div>
                {t('commissions.fields.carrierAssignedBy')}:{' '}
                <span className={styles.commissionInfoHeaderValue}>{getUserLabel(carrierAssignedBy)}</span>
            </div>
            <div>
                {t('commissions.form.qaplineId.relation')}:{' '}
                <span className={styles.commissionInfoHeaderValue}>{relation || '---'}</span>
            </div>
            <div>
                {t('commissions.form.qaplineId.number')}:{' '}
                <span className={styles.commissionInfoHeaderValue}>{number || '---'}</span>
            </div>
            <div>
                {t('commissions.form.qaplineId.week')}:{' '}
                <span className={styles.commissionInfoHeaderValue}>
                    {week ? week : '---'}/{year ? year : '---'}
                </span>
            </div>
        </div>
    );
};
