import React from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceDocument } from 'modules/invoicing/components/InvoiceDocument';
import { Spinner, Typography } from 'modules/ui';

import styles from './AttachmentPreview.module.scss';

export interface IAttachmentPreviewProps {
    blob: string | null;
    loading: boolean;
    type?: 'image' | 'pdf' | 'unknown';
}

export const AttachmentPreview: React.FC<IAttachmentPreviewProps> = ({ blob, loading, type }) => {
    const { t } = useTranslation();

    if (loading) {
        return (
            <div className={styles.loadingWrapper}>
                <Spinner fullScreen />
            </div>
        );
    }
    if (!blob) {
        return (
            <div className={styles.errorWrapper}>
                <Typography variant="h2">{t('attachments.filePreview.error')}</Typography>
            </div>
        );
    }
    if (type === 'image') {
        return (
            <div className={styles.imageTypeWrapper}>
                <img src={blob} alt="test" />
            </div>
        );
    }
    if (type === 'pdf') {
        return <InvoiceDocument pdfWrapperStyles={'commissionAttachments'} blobUrl={blob} loading={false} />;
    }

    return (
        <div className={styles.errorWrapper}>
            <Typography variant="h2">{t('attachments.filePreview.unsupported')}</Typography>
        </div>
    );
};
