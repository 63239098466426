import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { config } from 'config';
import { useDocumentTitle } from 'core/application/hooks';
import { useAppSelector } from 'hooks';
import { getCurrencyOptions, SearchBar, tableNames } from 'modules/common';
import { InvoiceSchema, useGetInvoicesQuery } from 'modules/invoicing';
import { PageContent, Subheader } from 'modules/layout';
import {
    BooleanIndicator,
    CellLink,
    createSelectColumn,
    formatPrice,
    formatUnixDate,
    getBooleanFilterMeta,
    getContainsFilterMeta,
    getDateRangeFilterMeta,
    getNumberRangeFilterMeta,
    getSelectFilterMeta,
    Table,
    TableActionsContainer,
    TableContainer,
    useTableExport,
    useTableState,
} from 'modules/table';
import { Button, Spinner } from 'modules/ui';
import { getBackstageFetchingList } from 'store/appSlice/selectors';

import { InvoicingStatusCountsBanner } from '../InvoicingStatusCountsBanner';

const columnHelper = createColumnHelper<InvoiceSchema>();

export const InvoicingPage = () => {
    const { t } = useTranslation();
    useDocumentTitle(t('nav.invoicing'));

    const navigate = useNavigate();
    const isBackstageFetching = useAppSelector(getBackstageFetchingList).includes('Invoices');

    const { tableProps, queryParams, paginationParams } = useTableState<InvoiceSchema>({
        rowIdKey: 'invoice_id',
        getCellStyles: ({ column }) => {
            const cellStyles: string[] = [];
            // DarkGray (columns)
            if (['customer_company', 'totalPrice', 'currency'].some((id) => id === column.id)) {
                cellStyles.push(Table.CellColor.LightGray);
            }
            if (column.id === 'invoiceNumber') {
                cellStyles.push(Table.CellTextColor.Primary, Table.CellTextFormat.Bold);
            }
            if (column.id === 'customer_company') {
                cellStyles.push(Table.CellTextFormat.SemiBold, Table.CellTextColor.White);
            }
            return cellStyles;
        },
        onRowClick: (row) => navigate(row.original.invoice_id.toString()),
        getIsRowSelectionDisabled: (row) => row.original.exported,
        tableName: tableNames.invoicing.list,
    });
    const { globalFilter, setGlobalFilter, rowSelection, allRowsSelected } = tableProps;
    const {
        handleExport,
        isLoading: isExportLoading,
        isEnabled: isExportEnabled,
    } = useTableExport({
        rowSelection,
        allRowsSelected,
        queryParams,
        endpoint: 'invoicesXML',
        method: 'POST',
        name: 'invoices.xml',
    });

    const {
        data: response,
        isLoading,
        isFetching,
    } = useGetInvoicesQuery({
        ...paginationParams,
        ...queryParams,
    });
    const { data, totalRows, totalUninvoiced } = response || {};

    const columns = useMemo(
        () => [
            columnHelper.accessor('invoiceNumber', {
                header: t('invoicing.fields.invoiceNumber'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('customer_company', {
                header: t('invoicing.fields.customer'),
                cell: (info) => (
                    <CellLink
                        path={`${config.routes.customers.list}/${info.row.original.customer_id}`}
                        title={info.getValue()}
                        bgColor="orange"
                    />
                ),
                meta: {
                    align: 'center',
                    disableRowClick: true,
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('pointDate', {
                header: t('invoicing.fields.pointDate'),
                cell: (info) => formatUnixDate(info.getValue()),
                meta: {
                    ...getDateRangeFilterMeta(),
                },
            }),
            columnHelper.accessor('totalPrice', {
                cell: (info) => formatPrice(info.getValue()),
                header: t('invoicing.fields.totalPrice'),
                meta: {
                    align: 'right',
                    ...getNumberRangeFilterMeta(),
                },
            }),
            columnHelper.accessor('currency', {
                header: t('invoicing.fields.currency'),
                meta: {
                    ...getSelectFilterMeta(getCurrencyOptions(t)),
                },
            }),
            columnHelper.accessor('issueDate', {
                header: t('invoicing.fields.issueDate'),
                cell: (info) => formatUnixDate(info.getValue()),
                meta: {
                    ...getDateRangeFilterMeta(),
                },
            }),
            columnHelper.accessor('dueDate', {
                header: t('invoicing.fields.dueDate'),
                cell: (info) => formatUnixDate(info.getValue()),
                meta: {
                    ...getDateRangeFilterMeta(),
                },
            }),
            columnHelper.accessor('invoiceSent', {
                header: t('invoicing.fields.invoiceSent'),
                cell: (info) => <BooleanIndicator value={info.getValue()} />,
                meta: {
                    align: 'center',
                    ...getBooleanFilterMeta([
                        { value: 'true', label: t('table.filtering.true') },
                        { value: 'false', label: t('table.filtering.false') },
                    ]),
                },
            }),
            columnHelper.accessor('exported', {
                header: t('invoicing.fields.exported'),
                cell: (info) => <BooleanIndicator value={info.getValue()} />,
                meta: {
                    align: 'center',
                    ...getBooleanFilterMeta([
                        { value: 'true', label: t('table.filtering.true') },
                        { value: 'false', label: t('table.filtering.false') },
                    ]),
                },
            }),
            createSelectColumn(columnHelper),
        ],
        [t],
    );

    if (isLoading) return <Spinner fullScreen />;

    return (
        <PageContent
            fullWidth
            subheader={
                <Subheader
                    title={t('nav.invoicing')}
                    endSlot={
                        <TableActionsContainer>
                            {isExportEnabled && (
                                <Button onClick={handleExport} variant="secondary" isLoading={isExportLoading}>
                                    {t('table.actions.export.toXML')}
                                </Button>
                            )}
                            <Button onClick={() => navigate('new')}>{t('invoicing.newInvoice')}</Button>
                        </TableActionsContainer>
                    }
                >
                    <SearchBar value={globalFilter} onChange={setGlobalFilter} />
                </Subheader>
            }
        >
            <InvoicingStatusCountsBanner />
            <TableContainer limit>
                <Table
                    data={data}
                    columns={columns}
                    totalCount={Number(totalRows)}
                    isLoading={(isLoading || isFetching) && !isBackstageFetching}
                    isFetching={isBackstageFetching}
                    {...tableProps}
                />
            </TableContainer>
        </PageContent>
    );
};

export default InvoicingPage;
