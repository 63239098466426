import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TAttachmentUploadTokenPayload } from 'core/auth/types';
import jwtDecode from 'jwt-decode';
import { useLazyGetAttachmentUploadLinkQuery } from 'modules/attachments/services';
import { FormGrid } from 'modules/form/components/FormGrid';
import { FormSection } from 'modules/form/components/FormSection';
import { Button, Typography } from 'modules/ui';
import { ReadOnlyInput } from 'modules/ui/components/ReadOnlyInput';
import moment from 'moment';
import { timestamp } from 'utils/formatTimestamp';

import styles from './AttachmentUploadLinkSection.module.scss';

export interface IAttachmentUploadLinkSectionProps {
    commissionId: number;
}

export const AttachmentUploadLinkSection: React.FC<IAttachmentUploadLinkSectionProps> = ({ commissionId }) => {
    const { t } = useTranslation();
    const [getAttachmentUploadLink, { data, isError, isFetching, isLoading }] = useLazyGetAttachmentUploadLinkQuery();

    const inputValue = useMemo(() => {
        if (isLoading || isFetching) {
            return t('commissions.export.uploadLink.loadingLink');
        }

        if (isError || !data) {
            return t('commissions.export.uploadLink.linkGenerationError');
        }

        return data.attachmentUploadLink;
    }, [data, isLoading, isFetching, isError]);

    const { expirationDate, daysRemaining } = useMemo(() => {
        if (!data?.attachmentUploadLink) return {};

        const uploadLinkUrl = new URL(data.attachmentUploadLink);
        const token = uploadLinkUrl.searchParams.get('uploadToken');
        if (!token) return {};

        const decodedToken = jwtDecode(token) as TAttachmentUploadTokenPayload;
        const momentDate = timestamp(decodedToken.exp);
        if (!momentDate) return {};

        return {
            expirationDate: momentDate.format('DD. MM. YYYY HH:mm'),
            daysRemaining: momentDate.diff(moment(), 'days'),
        };
    }, [data]);

    // Get new upload link with extended expiration date
    const handleRenewLink = async () => {
        // do not prefer cached value so we can generate new link multiple times
        await getAttachmentUploadLink({ commissionId, renew: true }, false);
    };

    // Fetch upload link on component mount
    useEffect(() => {
        if (!commissionId) return;
        const get = async () => {
            await getAttachmentUploadLink({ commissionId });
        };
        get();
    }, [commissionId]);

    return (
        <FormSection title={t('commissions.export.uploadLink.sectionTitle')}>
            <FormGrid columns={1}>
                <div className={styles.uploadLinkWrapper}>
                    <ReadOnlyInput showCopyButton={!!data} value={inputValue} />
                    <Button onClick={handleRenewLink} disabled={isFetching} isLoading={isFetching}>
                        <Typography color="white" variant="p">
                            {t('commissions.export.uploadLink.renew')}
                        </Typography>
                    </Button>
                </div>
                <div className={styles.expirationDateWrapper}>
                    <Typography variant="p">{t('commissions.export.uploadLink.validTo')}</Typography>
                    <Typography variant="p" fontWeight="normal">
                        {t('commissions.export.uploadLink.remainingTime', {
                            date: expirationDate || 'N/A',
                            days: daysRemaining || 'N/A',
                            count: daysRemaining || 100,
                        })}
                    </Typography>
                </div>
            </FormGrid>
        </FormSection>
    );
};
