import { T } from 'core/translation/types';
import { placeSchema } from 'modules/carriers';
import { CountryCodes } from 'modules/common';
import { phoneRegex } from 'utils';
import z from 'zod';

export enum ECustomerType {
    Potential = 'POTENTIAL',
    Active = 'ACTIVE',
}

export const locationCustomerSchema = (t: T) =>
    z.object({
        // basic
        company: z
            .string({
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullable(),
        countryCode: CountryCodes(t).nullable(),
        postalCode: z
            .string({
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullable(),
        city: z
            .string({
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullable(),
        street: z
            .string({
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullable(),
        longitude: z
            .number({
                invalid_type_error: t('form.errors.numberFormat'),
            })
            .nullable(),
        latitude: z
            .number({
                invalid_type_error: t('form.errors.numberFormat'),
            })
            .nullable(),
        // route direction
        loading: z.boolean(),
        discharge: z.boolean(),
        // credentials
        email: z.string().nullable(),
        firstName: z
            .string({
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullable(),
        lastName: z
            .string({
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullable(),
        phone: z
            .string({
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullable(),
        note: z.string().nullable(),
        location_id: z.number(),
        country: z.string().nullable(),
    });

const contactCustomerSchema = (t: T) =>
    z.object({
        email: z
            .string()
            .email({
                message: t('form.errors.emailFormat'),
            })
            .or(z.null())
            .or(z.literal('')),
        firstName: z
            .string({
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .min(1, { message: t('form.errors.firstNameRequired') }),
        lastName: z
            .string({
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .min(1, { message: t('form.errors.lastNameRequired') }),
        phone: z
            .string({
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .regex(phoneRegex, t('form.errors.phoneFormat'))
            .nullable()
            .or(z.literal('')),
        customerContact_id: z.number().optional(),
        deleted: z.boolean().optional(),
    });

const basicCustomerDetailSchema = (t: T) =>
    z.object({
        // basic
        company: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .min(1, { message: t('form.errors.required') }),
        country: z.string(),
        postalCode: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .min(1, { message: t('form.errors.required') }),
        city: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .min(1, { message: t('form.errors.required') }),
        street: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .min(1, { message: t('form.errors.required') }),
        email: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .min(1, { message: t('auth.email.error.required') })
            .email({
                message: t('form.errors.emailFormat'),
            }),
        // invoicing
        defaultDueDate: z
            .number({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.numberFormat'),
            })
            .nullable(),
        companyRegistrationNumber: z
            .union([
                z
                    .string({
                        invalid_type_error: t('form.errors.stringFormat'),
                    })
                    .min(8, { message: t('form.errors.minLengthOther', { minLength: 8 }) }),
                z.string().length(0),
            ])
            .nullable(),
        taxId: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .min(1, { message: t('form.errors.required') }),
        // note
        note: z
            .string({
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullable(),

        // customer_id: z.number(),
        // qid: z.number(),
        deleted: z.boolean().nullish(),
        // editedBy: z.string(),
        // firstName: z.null(),
        // lastName: z.null(),
        // number: z.number(),
        // phone: z.string(),
        countryCode: CountryCodes(t),
        // addedBy: z.string(),
        // ts_added: z.null(),
        // ts_edited: z.string(),
    });

export const customerDetailSchema = (t: T) =>
    basicCustomerDetailSchema(t).extend({
        location: z.array(locationCustomerSchema(t)),
        customercontact: z.array(contactCustomerSchema(t)),
        // place
        place: placeSchema(t).partial().optional(),
    });

export const customerSchema = (t: T) =>
    z.object({
        customer_id: z.number(),
        company: z.string(),
        note: z.string().nullable(),
        street: z.string(),
        postalCode: z.string(),
        country: z.string(),
        countryCode: CountryCodes(t),
        city: z.string(),
        type: z.nativeEnum(ECustomerType),
    });

export const customerPageSchema = (t: T) =>
    z.object({
        data: z.array(customerSchema(t)),
        totalRows: z.number(),
    });

const customerDetailLoading = () => z.object({ loading_id: z.number(), company: z.string() });
const customerDetailDischarge = () => z.object({ discharge_id: z.number(), company: z.string() });

export type CustomerDetailLoading = z.infer<ReturnType<typeof customerDetailLoading>>;
export type CustomerDetailDischarge = z.infer<ReturnType<typeof customerDetailDischarge>>;
export type BasicCustomerDetailSchema = z.infer<ReturnType<typeof basicCustomerDetailSchema>>;
export type ContactCustomerSchema = z.infer<ReturnType<typeof contactCustomerSchema>>;
export type LocationCustomerSchema = z.infer<ReturnType<typeof locationCustomerSchema>>;
export type CustomerDetailSchema = z.infer<ReturnType<typeof customerDetailSchema>>;
export type CustomerSchema = z.infer<ReturnType<typeof customerSchema>>;
export type CustomerPageSchema = z.infer<ReturnType<typeof customerPageSchema>>;
