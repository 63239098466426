import { CellContext } from '@tanstack/react-table';
import { T } from 'core/translation/types';
import { Countries } from 'modules/common';

export const getCountryColumnCell = <Val extends string>(t: T) => {
    return (cell: CellContext<any, Val | undefined | null> | undefined) => {
        if (!cell) return '-';
        const value = cell.getValue();
        if (!value) return '-';
        return t(`common.country.${value as keyof typeof Countries}`);
    };
};
