import { useState } from 'react';
import React from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNonTypedTranslation } from 'core/translation';
import { getCountryOptions } from 'modules/common';
import { LocationCustomerSchema, locationCustomerSchema } from 'modules/customers';
import { Button, Modal, Typography } from 'modules/ui';
import { handleErrorsWithNoInputs } from 'utils/handleErrorsWithNoInputs';

import { CheckboxField } from '../CheckboxField';
import { ComboBoxField } from '../ComboBoxField';
import { VisualFormInputsContext } from '../Form/Form';
import { FormActionButton } from '../FormActionButton';
import { FormCard } from '../FormCard';
import { FormGrid } from '../FormGrid/FormGrid';
import { NumberField } from '../NumberField';
import { TextAreaField } from '../TextAreaField';
import { TextField } from '../TextField';
import { LocationAutocomplete } from './LocationAutocomplete';
import { Map } from './Map';

import styles from './LocationItemModal.module.scss';

interface ILocationItemProps {
    data?: LocationCustomerSchema;
    isNew: boolean;
    onRemoveItem?: () => void;
    onCloseItem: () => void;
    onSaveItem: (data: LocationCustomerSchema) => void;
}

export const LocationItemModal: React.FC<ILocationItemProps> = ({
    data,
    isNew,
    onRemoveItem,
    onCloseItem,
    onSaveItem,
}) => {
    const { t } = useTranslation();
    const { tnt } = useNonTypedTranslation();
    const [showRemoveConfirmationPopup, setShowRemoveConfirmationPopup] = useState(false);
    const [showCloseConfirmationPopup, setShowCloseConfirmationPopup] = useState(false);
    const [visualInputsList, setVisualInputsList] = useState<string[]>([]);

    const methods = useForm<LocationCustomerSchema>({
        defaultValues: data || {
            // basic
            location_id: -1 - Math.random(),
            company: null,
            country: null,
            countryCode: null,
            postalCode: null,
            city: null,
            street: null,
            longitude: 0,
            latitude: 0,
            // route direction
            loading: true,
            discharge: true,
            // credentials
            firstName: null,
            lastName: null,
            email: null,
            phone: null,
            note: null,
        },
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(locationCustomerSchema(t)),
    });

    const { handleSubmit, control, reset, formState } = methods;
    const { company } = useWatch({ control });

    const onSubmit = (data: LocationCustomerSchema) => {
        onSaveItem(data);
    };

    const onCloseButtonClick = () => {
        if (formState.isDirty) return setShowCloseConfirmationPopup(true);
        onCloseItem();
    };

    const preventClick = (e: React.MouseEvent) => e.stopPropagation();

    return (
        <div className={styles.modal} onClick={onCloseButtonClick}>
            <FormProvider {...methods}>
                <VisualFormInputsContext.Provider
                    value={{
                        visualInputsList,
                        setVisualInputsList,
                    }}
                >
                    <div id="location-form" className={styles.locationForm} onReset={() => reset()}>
                        <div className={styles.container} onClick={preventClick}>
                            <FormCard
                                title=""
                                headerStartSlot={
                                    <div className={styles.locationTitle}>
                                        <Typography variant="h4">{company}</Typography>
                                    </div>
                                }
                            >
                                <FormGrid columns={2}>
                                    <FormGrid columns={2} rows={3}>
                                        <LocationAutocomplete />
                                        <Typography
                                            variant="p"
                                            fontWeight="bold"
                                            style={{ gridColumnStart: 1, gridColumnEnd: 3, display: 'flex' }}
                                        >
                                            {t('customers.form.locations.labels.firm')}
                                        </Typography>
                                        <TextField name="company" label={t('customers.form.locations.company')} />
                                        <TextField name="street" label={t('customers.form.locations.street')} />
                                        <TextField name="postalCode" label={t('customers.form.locations.postalCode')} />
                                        <TextField name="city" label={t('customers.form.locations.city')} />
                                        <ComboBoxField
                                            name="countryCode"
                                            label={t('customers.form.locations.country')}
                                            options={getCountryOptions(tnt)}
                                        />
                                        <FormGrid columns={2}>
                                            <NumberField
                                                name="latitude"
                                                label={t('customers.form.locations.latitude')}
                                            />
                                            <NumberField
                                                name="longitude"
                                                label={t('customers.form.locations.longitude')}
                                            />
                                        </FormGrid>
                                    </FormGrid>
                                    <Map />
                                    <div>
                                        <Typography
                                            variant="p"
                                            fontWeight="bold"
                                            className={styles['label']}
                                            style={{ gridColumnStart: 1, gridColumnEnd: 3, display: 'flex' }}
                                        >
                                            {t('customers.form.locations.labels.details')}
                                        </Typography>
                                        <Typography className={styles['label']} fontWeight="medium" variant="p">
                                            {t('customers.form.locations.routeDirection.label')}
                                        </Typography>
                                        <FormGrid columns={2}>
                                            <CheckboxField
                                                name="loading"
                                                label={t('customers.form.locations.routeDirection.loading')}
                                            />
                                            <CheckboxField
                                                name="discharge"
                                                label={t('customers.form.locations.routeDirection.discharge')}
                                            />
                                        </FormGrid>
                                    </div>
                                    <FormGrid columns={2}>
                                        <Typography
                                            variant="p"
                                            fontWeight="bold"
                                            style={{
                                                gridColumnStart: 1,
                                                gridColumnEnd: 3,
                                                display: 'flex',
                                                paddingTop: 8,
                                            }}
                                        >
                                            {t('customers.form.locations.labels.contactPerson')}
                                        </Typography>
                                        <TextField name="firstName" label={t('customers.form.contacts.firstName')} />
                                        <TextField name="lastName" label={t('customers.form.contacts.lastName')} />
                                        <TextField name="phone" label={t('customers.form.contacts.phone')} />
                                        <TextField name="email" label={t('customers.form.contacts.email')} />
                                    </FormGrid>
                                    <FormGrid columns={1} style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                                        <TextAreaField
                                            name="note"
                                            label={t('customers.form.note.sectionTitle')}
                                            placeholder={t('customers.form.note.placeholder')}
                                        />
                                    </FormGrid>
                                </FormGrid>
                                <div className={styles.buttons}>
                                    <div style={{ display: 'flex', gap: 16 }}>
                                        <Button onClick={onCloseButtonClick} variant="secondary">
                                            {t('form.cancel')}
                                        </Button>
                                        <Button
                                            onClick={handleSubmit(onSubmit, (error) =>
                                                handleErrorsWithNoInputs(error, visualInputsList),
                                            )}
                                            variant="primary"
                                        >
                                            {t('customers.form.locations.savelocation')}
                                        </Button>
                                    </div>
                                    <div className={styles.actionBtn}>
                                        {!isNew && (
                                            <FormActionButton
                                                variant="danger"
                                                onClick={() => setShowRemoveConfirmationPopup(true)}
                                            >
                                                {t('customers.form.locations.removeAddress')}
                                            </FormActionButton>
                                        )}
                                    </div>
                                </div>
                            </FormCard>
                        </div>

                        {/* if we have unsaved changes, and trying to close the item */}
                        {showCloseConfirmationPopup && (
                            <Modal
                                onClick={() => setShowCloseConfirmationPopup(false)}
                                label={t('customers.form.locations.modals.unsavedChanges.label')}
                                description={t('customers.form.locations.modals.unsavedChanges.description')}
                                cancelComponent={
                                    <Button
                                        className={styles.btn}
                                        type="button"
                                        variant="secondary"
                                        onClick={onCloseItem}
                                    >
                                        {t('customers.form.locations.modals.unsavedChanges.cancel')}
                                    </Button>
                                }
                                approveComponent={
                                    <Button
                                        className={styles.btn}
                                        type="button"
                                        variant="primary"
                                        onClick={handleSubmit(onSubmit, (error) => console.log(error))}
                                    >
                                        {t('customers.form.locations.modals.unsavedChanges.approve')}
                                    </Button>
                                }
                            />
                        )}
                        {/* if we remove the item */}
                        {showRemoveConfirmationPopup && (
                            <Modal
                                onClick={() => setShowRemoveConfirmationPopup(false)}
                                label={t('customers.form.locations.modals.remove.label')}
                                cancelComponent={
                                    <Button
                                        className={styles.btn}
                                        type="button"
                                        variant="secondary"
                                        onClick={() => setShowRemoveConfirmationPopup(false)}
                                    >
                                        {t('customers.form.locations.modals.remove.cancel')}
                                    </Button>
                                }
                                approveComponent={
                                    <Button
                                        className={styles.btn}
                                        type="button"
                                        danger
                                        variant="primary"
                                        onClick={onRemoveItem}
                                    >
                                        {t('customers.form.locations.modals.remove.approve')}
                                    </Button>
                                }
                            />
                        )}
                    </div>
                </VisualFormInputsContext.Provider>
            </FormProvider>
        </div>
    );
};
