import { Language } from 'core/translation/i18next/i18next';
import { T } from 'core/translation/types';
import { Currency } from 'modules/common';

import { getLocaleByLanguage } from './locale';

const EMPTY_PLACEHOLDER = '-';

export type TFormatPriceArgs = {
    roundingMethod?: 'floor' | 'ceil' | 'round' | 'truncate' | 'none';
    numberOfDigits?: number;
    lang?: Language;
    currency?: Currency;
};

/**
 * @param value - number to format
 * @param t - translation function
 * @param args - additional arguments
 * @param args.numberOfDigits - number of digits after decimal point (default 2)
 * @param args.roundingMethod - method of rounding the number (default none)
 * @param args.currency - currency to display (default CZK)
 * @param args.lang - language to display (default cs)
 * @returns
 */
export const formatPrice = (
    value: number | null | undefined,
    t: T,
    { numberOfDigits = 2, roundingMethod, currency = Currency.CZK, lang = Language.cs }: TFormatPriceArgs = {},
) => {
    if (value === undefined || value === null) {
        return EMPTY_PLACEHOLDER;
    }
    const rounded = (() => {
        switch (roundingMethod) {
            case 'floor':
                return Math.floor(value);
            case 'round':
                return Math.round(value);
            case 'truncate':
                return Math.trunc(value);
            case 'ceil':
                return Math.ceil(value);
            case 'none':
            default:
                return value;
        }
    })();
    const formatted = rounded.toLocaleString(getLocaleByLanguage(lang), {
        minimumFractionDigits: numberOfDigits,
        maximumFractionDigits: numberOfDigits,
    });

    return `${formatted} ${t(`common.currency.${currency}`)}`;
};
