import { DispatcherSearchResponse } from 'modules/dispatcherSearch/types';

import { DispatcherSearchTableItem } from '../../DispatcherSearchTables/hooks/useDispatcherSearchTable';

export const transformDispatcherSearchToTableItem = (
    response: DispatcherSearchResponse | undefined,
): DispatcherSearchTableItem[] => {
    if (!response) return [];
    return response.data.map((dispatcher) => ({
        dispatcher_id: dispatcher.id,
        country: dispatcher.carrier.countryCode ? dispatcher.carrier.countryCode : '',
        countryCode: dispatcher.carrier.countryCode,
        company: dispatcher.carrier.name,
        fullName: dispatcher.name,
        lastCommissionDate: Number(dispatcher.lastCommissionDate),

        // BE returns lastSuccessReasonCommission only when searching by "commission"
        lastCommissionId: dispatcher.lastSuccessReasonCommission
            ? Number(dispatcher.lastSuccessReasonCommission.id)
            : null,
        lastCommissionNumber: dispatcher.lastSuccessReasonCommission
            ? dispatcher.lastSuccessReasonCommission.number
            : null,
        popUpData: dispatcher.lastSuccessReasonCommission
            ? {
                  priceCarrier: dispatcher.lastSuccessReasonCommission.priceCarrier,
                  customer: dispatcher.lastSuccessReasonCommission.customer,
                  loading: dispatcher.lastSuccessReasonCommission.loading,
                  discharge: dispatcher.lastSuccessReasonCommission.discharge,
              }
            : null,
    }));
};
