import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';
import { zodResolver } from '@hookform/resolvers/zod';
import { IconEmail } from 'assets/icons';
import { useAuth } from 'core/auth';
import { AuthRole } from 'core/auth/types';
import { useAppDispatch, useAppSelector } from 'hooks';
import { createFullName } from 'modules/carriers/utils';
import {
    CommissionLoadingDischargeSchema,
    CommissionLoadingSchema,
    DispatcherSearchParameters,
    SendCommissionInquiryModal,
} from 'modules/commissions';
import { useEmailMessageSaverType } from 'modules/commissions/hooks/useEmailMessageSaver';
import { SelectedDispatcher, useSelectedDispatchers } from 'modules/commissions/hooks/useSelectedDispatchers';
import { CommissionCarrierForm, Form, FormSection, TextAreaField, VisualFormInputsContext } from 'modules/form';
import { DispatcherSearchTables } from 'modules/form/components/DispatcherSearchTables';
import { DispatcherSearchTableItem } from 'modules/form/components/DispatcherSearchTables/hooks/useDispatcherSearchTable';
import { setExistedDispatchers } from 'modules/offers/services';
import { usePostEnquiryEmailMutation, usePutOfferMutation } from 'modules/offers/services/api';
import { useLazyPostDispatcherEmailQuery } from 'modules/onboarding/services';
import { Button, Modal, Typography } from 'modules/ui';
import { Subset } from 'types/helpers';
import { handleErrorsWithNoInputs } from 'utils/handleErrorsWithNoInputs';

import { buildEnquiryEmailBody } from '../../../utils';
import { ContactDispatcherModal } from '../ContactDispatcherModal';
import { ContactedDispatchersSection } from '../ContactedDispatchersSection';
import { ModifyParametersModal } from '../ModifyParametersModal';
import { OffersSection } from '../OffersSection';
import { PlacesSection } from '../PlacesSection';
import { VehicleParametersSection } from '../VehicleParametersSection';
import {
    AdminOfferStatusValue,
    EnquirySchemaComplete,
    OfferSchema,
    OffersDetailAdminFormSchema,
    offersDetailAdminFormSchema,
} from './../../../types';

import styles from './OffersDetailAdminForm.module.scss';

interface IOffersDetailAdminFormProps {
    fetchedData: EnquirySchemaComplete & { state: AdminOfferStatusValue };
    reopenEnquiry: () => void;
    chooseWinner: (offerId: number) => void;
    prepareData: (data: Partial<OffersDetailAdminFormSchema>, selectedDispatchers?: number[]) => void;
}

export const OffersDetailAdminForm: React.FC<IOffersDetailAdminFormProps> = ({
    fetchedData,
    reopenEnquiry,
    chooseWinner,
    prepareData,
}) => {
    const { t } = useTranslation();
    // CONTEXT HOOKS
    const { map } = useMap();
    const { user } = useAuth();
    const { selectedDispatchers, setSelectedDispatchers } = useSelectedDispatchers();
    const { dispatcherSearchParameters } = useAppSelector((state) => state.commission);

    const dispatch = useAppDispatch();

    // STATE HOOKS
    const [selectedLocationIdx, setSelectedLocationIdx] = useState<number | null>(null);
    const [searchedCarriers, setSearchedCarriers] = useState<DispatcherSearchTableItem[]>([]);
    const [showFindCarrierModal, setShowFindCarrieModal] = useState(false);
    const [dispatcherIdToContact, setDispatcherIdToContact] = useState<number | null>(null);
    const [offerIdToWin, setOfferIdToWin] = useState<number | null>(null);
    const [visualInputsList, setVisualInputsList] = useState<string[]>([]);
    const [showEnquiryModal, setShowEnquiryModal] = useState(false);
    const [showModifyParametersModal, setShowModifyParametersModal] = useState(false);
    const [showRemoveWinnerModal, setShowRemoveWinnerModal] = useState(false);

    // FETCH HOOKS
    const [updateDispatcherOffer] = usePutOfferMutation();
    const [sendDispatcherMessage] = useLazyPostDispatcherEmailQuery();
    const [sendEnquiryEmail] = usePostEnquiryEmailMutation();

    // FORM's INITIALIZATION & HOOKS
    const methods = useForm<OffersDetailAdminFormSchema>({
        defaultValues: {
            note: fetchedData.note,
            parameters: fetchedData.parameters,
            offers: fetchedData.offers.map((item) => ({
                offer_id: item.offer_id,
                note: item.note,
            })),
        },
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(offersDetailAdminFormSchema(t)),
    });

    const { handleSubmit } = methods;

    const { offers, contactedDispatchers, commissionLoadingDischarge } = useMemo(() => {
        return {
            offers: fetchedData?.offers,
            contactedDispatchers: fetchedData.contactedDispatchers,
            commissionLoadingDischarge: [
                ...fetchedData.commission.commissionloading.map((item) => ({
                    ...item,
                    isLoading: true,
                    location: item.location
                        ? {
                              ...item.location,
                              latitude: Number(item.location.latitude),
                              longitude: Number(item.location.longitude),
                          }
                        : null,
                })),
                ...fetchedData.commission.commissiondischarge.map((item) => ({
                    ...item,
                    isLoading: false,
                    location: item.location
                        ? {
                              ...item.location,
                              latitude: Number(item.location.latitude),
                              longitude: Number(item.location.longitude),
                          }
                        : null,
                })),
            ],
        };
    }, [fetchedData]);

    const { vehicleTypes = [], requiredFeatures = [], requiredFeaturesSome = [] } = fetchedData.parameters;
    const isOfferClosed = fetchedData.state === AdminOfferStatusValue.Closed;
    const alreadyContactedDispatchersIds = contactedDispatchers.map((item) => item.dispatcher_id);
    const newSelectedDispatchers = selectedDispatchers.filter(
        (item) => !alreadyContactedDispatchersIds.includes(item.dispatcherId),
    );

    // set the default selected dispatchers
    useEffect(() => {
        const selected: SelectedDispatcher[] = contactedDispatchers?.map((item) => ({
            dispatcherId: item.dispatcher?.dispatcher_id || 0,
        }));
        setSelectedDispatchers(selected || []);
        dispatch(
            setExistedDispatchers(
                contactedDispatchers?.map((item) => ({
                    dispatcher_id: item.dispatcher_id,
                    country: item.dispatcher.carrier.country,
                    countryCode: item.dispatcher.carrier.countryCode,
                    company: item.dispatcher.carrier.company,
                    fullName: createFullName(item.dispatcher.firstName, item.dispatcher.lastName),

                    lastCommissionDate: null,
                    lastCommissionId: null,
                    lastCommissionNumber: null,
                    popUpData: null,
                })) || [],
            ),
        );
    }, []);

    // CALLBACK FUNCTIONS
    const onNoteChange = (value: string, offer_id: number) => {
        updateDispatcherOffer({ data: { note: value || null }, id: offer_id });
    };

    const onLoadingDischargeClick = (index: number) => {
        if (!commissionLoadingDischarge) return;
        if (selectedLocationIdx === index) return setSelectedLocationIdx(null);
        const item = commissionLoadingDischarge[index];
        if (!item.location || !map) return;

        setSelectedLocationIdx(index);
        map.flyTo({
            center: [item.location.longitude || 0, item.location.latitude || 0],
        });
    };

    const handleContactCarrier = async (subject: string, message: string, dispatcherId: number) => {
        const sendEmailRequestBody = {
            dispatcherId,
            subject,
            body: message,
        };
        const { isError } = await sendDispatcherMessage(sendEmailRequestBody);

        setDispatcherIdToContact(null);
        if (isError) return toast.error(t('offers.contactDispatcherModal.errorMessage'));
        toast.success(t('offers.contactDispatcherModal.successMessage'));
    };

    const handleChooseWinner = async (dispatcherId: number) => {
        chooseWinner(dispatcherId);
        setOfferIdToWin(null);
    };

    const onSubmit = (data: OffersDetailAdminFormSchema) => {
        prepareData(
            { note: data.note, offers: data.offers },
            selectedDispatchers.map((item) => item.dispatcherId),
        );
    };

    const handleSendEnquiry = async (
        data: useEmailMessageSaverType['messages']['enquiry'],
        loadings: Subset<CommissionLoadingSchema & { companyName: string }>[],
        discharges: Subset<CommissionLoadingSchema & { companyName: string }>[],
    ) => {
        if (!user) return;
        setShowEnquiryModal(false);

        if (!selectedDispatchers.length || !user)
            return toast.error(t('commissions.sendCommissionInquiryModal.noEmail'));

        const body = buildEnquiryEmailBody({
            user,
            data,
            dispatchers: newSelectedDispatchers,
            loadings,
            discharges,
            enquiry_id: fetchedData.enquiry_id,
            parameters: dispatcherSearchParameters,
        });

        try {
            const res = await sendEnquiryEmail(body);
            if (res && 'error' in res) throw new Error();
            toast.success(t('commissions.sendCommissionInquiryModal.success'));
        } catch (_) {
            return toast.error(t('commissions.sendCommissionInquiryModal.error'));
        }
    };

    const loadings = commissionLoadingDischarge?.filter((item) => item.isLoading);
    const firstLoading = commissionLoadingDischarge[0];

    const discharges = commissionLoadingDischarge?.filter((item) => !item.isLoading);
    const lastDischarge = discharges?.[discharges.length - 1];

    return (
        <>
            <FormProvider {...methods}>
                <VisualFormInputsContext.Provider
                    value={{
                        visualInputsList,
                        setVisualInputsList,
                    }}
                >
                    <div className={styles.container}>
                        <div className={styles.content}>
                            <Form
                                id="offer-detail-form"
                                onSubmit={handleSubmit(onSubmit, (error) =>
                                    handleErrorsWithNoInputs(error, visualInputsList),
                                )}
                                className={styles.form}
                            >
                                <PlacesSection
                                    commissionLoadingDischarge={
                                        commissionLoadingDischarge as CommissionLoadingDischargeSchema[]
                                    }
                                    selectedLocationIdx={selectedLocationIdx}
                                    setSelectedLocationIdx={setSelectedLocationIdx}
                                    onLoadingDischargeClick={onLoadingDischargeClick}
                                />
                                <OffersSection
                                    data={offers}
                                    isOfferClosed={isOfferClosed}
                                    setShowRemoveWinnerModal={setShowRemoveWinnerModal}
                                    onNoteChange={onNoteChange}
                                    setDispatcherIdToContact={setDispatcherIdToContact}
                                    setOfferIdToWin={setOfferIdToWin}
                                />
                                <ContactedDispatchersSection
                                    data={contactedDispatchers}
                                    offers={offers}
                                    isOfferClosed={isOfferClosed}
                                    setShowFindCarrieModal={setShowFindCarrieModal}
                                />
                                <VehicleParametersSection
                                    vehicleTypes={vehicleTypes}
                                    requiredFeaturesSome={requiredFeaturesSome}
                                    requiredFeatures={requiredFeatures}
                                    role={AuthRole.USER}
                                    disableParametersEdit={isOfferClosed}
                                    setShowModifyParametersModal={setShowModifyParametersModal}
                                />
                                <FormSection title={t('offers.form.noteSection.title')} className={styles.formSection}>
                                    <TextAreaField
                                        name="note"
                                        label=""
                                        placeholder={t('offers.form.noteSection.title')}
                                    />
                                </FormSection>
                            </Form>
                        </div>
                    </div>
                </VisualFormInputsContext.Provider>
            </FormProvider>
            {showEnquiryModal && fetchedData && handleSendEnquiry && commissionLoadingDischarge && (
                <SendCommissionInquiryModal
                    totalWeight={
                        `${fetchedData.commission?.commissionitem?.reduce<number>(
                            (acc, field) => (acc += Number(field.weightBrutto) || 0),
                            0,
                        )} kg` || '0 kg'
                    }
                    totalLoadingMeters={
                        `${fetchedData.commission?.commissionitem?.reduce<number>(
                            (acc, field) => (acc += Number(field.loadingMeters) || 0),
                            0,
                        )} m` || '0 m'
                    }
                    loadings={
                        loadings.map((loading) => ({
                            ...loading,
                            companyName: loading.location?.company,
                        })) as CommissionLoadingDischargeSchema[]
                    }
                    discharges={
                        discharges.map((discharge) => ({
                            ...discharge,
                            companyName: discharge.location?.company,
                        })) as CommissionLoadingDischargeSchema[]
                    }
                    newSelectedDispatchersCount={newSelectedDispatchers.length}
                    handleEmail={handleSendEnquiry}
                    onClose={() => setShowEnquiryModal(false)}
                />
            )}
            {showModifyParametersModal && firstLoading && lastDischarge && (
                <ModifyParametersModal
                    setShowModal={setShowModifyParametersModal}
                    onSubmit={(dispatcherSearchParameters: DispatcherSearchParameters) => {
                        prepareData({ parameters: dispatcherSearchParameters });
                        setShowModifyParametersModal(false);
                    }}
                    loadingParams={{
                        lat: firstLoading.location?.latitude || 0,
                        lon: firstLoading.location?.longitude || 0,
                        radius: 40,
                    }}
                    dischargeParams={{
                        lat: lastDischarge.location?.latitude || 0,
                        lon: lastDischarge.location?.longitude || 0,
                        radius: 40,
                    }}
                    defaultValues={fetchedData.parameters}
                />
            )}
            {showFindCarrierModal && firstLoading && lastDischarge && (
                <div className={styles.findCarrierModal} onClick={() => setShowFindCarrieModal(false)}>
                    <div className={styles.findCarrierModalWrapper} onClick={(e) => e.stopPropagation()}>
                        <div className={styles.findCarrierModalTitle}>
                            <Typography variant="headline-h4">{t('offers.modalFindCarriers.title')}</Typography>
                        </div>
                        <CommissionCarrierForm
                            defaultValues={fetchedData.parameters}
                            locations={{
                                loading: {
                                    lat: firstLoading.location?.latitude || 0,
                                    lon: firstLoading.location?.longitude || 0,
                                    radius: 40,
                                },
                                discharge: {
                                    lat: lastDischarge.location?.latitude || 0,
                                    lon: lastDischarge.location?.longitude || 0,
                                    radius: 40,
                                },
                            }}
                            onFetchedData={(carriers: DispatcherSearchTableItem[]) => {
                                setSearchedCarriers(carriers);
                            }}
                        />
                        <DispatcherSearchTables
                            data={searchedCarriers}
                            defaultSelectedDispatchers={contactedDispatchers}
                            disabledIds={alreadyContactedDispatchersIds}
                        />
                        <div className={styles.buttons}>
                            <Button variant="secondary" onClick={() => setShowFindCarrieModal(false)}>
                                {t('offers.modalFindCarriers.cancel')}
                            </Button>
                            <Button
                                disabled={newSelectedDispatchers.length < 1}
                                variant="primary"
                                onClick={() => {
                                    setShowFindCarrieModal(false);
                                    setShowEnquiryModal(true);
                                }}
                            >
                                {t('offers.modalFindCarriers.approve')} <IconEmail iconBackgroundColor="white" />
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            {dispatcherIdToContact && (
                <ContactDispatcherModal
                    offer={offers.find((offer) => offer.dispatcher_id === dispatcherIdToContact) as OfferSchema}
                    enquiryNumber={fetchedData.number}
                    onClose={() => setDispatcherIdToContact(null)}
                    handleEmail={(subject: string, message: string) =>
                        handleContactCarrier(subject, message, dispatcherIdToContact)
                    }
                />
            )}
            {offerIdToWin && (
                <Modal
                    onClick={() => setOfferIdToWin(null)}
                    label={t('offers.chooseWinnerModal.label')}
                    description={t('offers.chooseWinnerModal.description')}
                    cancelComponent={
                        <Button
                            className={styles.modalBtn}
                            type="button"
                            variant="secondary"
                            onClick={() => setOfferIdToWin(null)}
                        >
                            {t('offers.chooseWinnerModal.cancel')}
                        </Button>
                    }
                    approveComponent={
                        <Button
                            className={styles.modalBtn}
                            type="button"
                            variant="primary"
                            onClick={() => handleChooseWinner(offerIdToWin)}
                        >
                            {t('offers.chooseWinnerModal.approve')}
                        </Button>
                    }
                />
            )}
            {showRemoveWinnerModal && (
                <Modal
                    onClick={() => setShowRemoveWinnerModal(false)}
                    label={t('offers.modalRemoveWinner.label')}
                    description={t('offers.modalRemoveWinner.description')}
                    cancelComponent={
                        <Button
                            className={styles.modalBtn}
                            type="button"
                            variant="secondary"
                            onClick={() => setShowRemoveWinnerModal(false)}
                        >
                            {t('offers.modalRemoveWinner.cancel')}
                        </Button>
                    }
                    approveComponent={
                        <Button
                            className={styles.modalBtn}
                            type="button"
                            danger
                            variant="primary"
                            onClick={() => reopenEnquiry()}
                        >
                            {t('offers.modalRemoveWinner.approve')}
                        </Button>
                    }
                />
            )}
        </>
    );
};
