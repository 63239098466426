import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useAttachmentUpload } from 'modules/attachments/contexts/attachmentUpload';
import { useListAttachmentsQuery } from 'modules/attachments/services';
import { Typography } from 'modules/ui';

import { AttachmentsTable } from '../../AttachmentsTable';

import styles from './UploadedAttachmentsList.module.scss';

export interface IUploadedAttachmentsListProps {}

export const UploadedAttachmentsList: React.FC<IUploadedAttachmentsListProps> = () => {
    const { t } = useTranslation();

    const { uploadTokenPayload } = useAttachmentUpload();
    const { data: attachments, isLoading: isLoadingAttachments } = useListAttachmentsQuery(
        {
            commissionId: uploadTokenPayload?.commissionId,
        },
        { skip: !uploadTokenPayload?.commissionId },
    );

    return (
        <motion.div layout="position" className={styles.card}>
            <Typography variant="h3" style={{ marginBottom: 20 }}>
                {t('attachmentsUpload.uploadedFiles.title')}
            </Typography>
            <AttachmentsTable
                tableName="uploadedAttachments"
                attachments={attachments || []}
                isLoading={isLoadingAttachments}
                commissionId={uploadTokenPayload?.commissionId}
                columnsVisibility={{ actions: false, commission: false, uploadedBy: false }}
                hideHeaderActions
                hideInvoiceState
            />
        </motion.div>
    );
};
