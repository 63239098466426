import React from 'react';
import { AttachmentPreview } from 'modules/attachments/components/AttachmentPreview';
import { MailAttachmentFileButton } from 'modules/attachments/components/MailAttachmentFileButton';
import { PrintAttachmentFileButton } from 'modules/attachments/components/PrintAttachmentFileButton';
import { useGetAttachmentFileQuery, useGetAttachmentQuery } from 'modules/attachments/services';
import { getAttachmentFileType } from 'modules/attachments/utils';

import styles from './CarrierInvoiceModal.module.scss';

export interface ICarrierInvoiceModalProps {
    attachmentId: number;
    onClose: () => void;
}

export const CarrierInvoiceModal: React.FC<ICarrierInvoiceModalProps> = ({ attachmentId, onClose }) => {
    const { data: attachmentData } = useGetAttachmentQuery({ attachmentId });
    const { data: attachmentFileData, isError, isLoading } = useGetAttachmentFileQuery({ attachmentId });

    return (
        <div className={styles.modal} onClick={onClose}>
            <div className={styles.container} onClick={(e) => e.stopPropagation()}>
                <AttachmentPreview
                    type={attachmentData?.mimeType ? getAttachmentFileType(attachmentData.mimeType) : undefined}
                    blob={attachmentFileData?.data || null}
                    loading={isLoading}
                />
                {!isError && attachmentFileData && (
                    <div className={styles.buttonContainer}>
                        <PrintAttachmentFileButton attachmentId={attachmentId} />
                        <MailAttachmentFileButton attachmentId={attachmentId} />
                    </div>
                )}
            </div>
        </div>
    );
};
