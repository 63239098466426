import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { useNonTypedTranslation } from 'core/translation';
import { motion } from 'framer-motion';
import { AttachmentsUploadDropZone } from 'modules/attachments/components/AttachmentsUploadDropZone';
import { AttachmentsList } from 'modules/attachments/components/upload/AttachmentsList';
import { TAttachmentUploadFormSchema } from 'modules/attachments/types';
import { Typography } from 'modules/ui';

import styles from './UploadSectionField.module.scss';

export interface IUploadSectionFieldProps {
    fieldName: 'invoice' | 'deliveryNotes';
}

export const UploadSectionField: React.FC<IUploadSectionFieldProps> = ({ fieldName }) => {
    const { tnt } = useNonTypedTranslation();
    const { fields, append, remove } = useFieldArray<TAttachmentUploadFormSchema>({ name: fieldName });

    const handleDroppedFiles = (files: File[]) => {
        files.forEach((file) => append({ file }));
    };

    const configs = {
        invoice: {
            maxFiles: 1,
            multiple: false,
        },
        deliveryNotes: {
            maxFiles: undefined,
            multiple: true,
        },
    };
    const currentConfig = configs[fieldName];

    return (
        <motion.div layout className={styles.card}>
            <motion.div layout style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h3" style={{ marginBottom: 8 }}>
                    {tnt(`attachmentsUpload.${fieldName}.title`)}
                </Typography>
                <Typography variant="p" fontWeight="normal" style={{ marginBottom: 20 }}>
                    {tnt(`attachmentsUpload.${fieldName}.subTitle`)}
                </Typography>
            </motion.div>
            <AttachmentsList attachments={fields} onRemove={remove} />
            <motion.div style={{ marginTop: 10 }} layout>
                <AttachmentsUploadDropZone
                    filesCount={fields.length}
                    maxFiles={currentConfig.maxFiles}
                    multiple={currentConfig.multiple}
                    onDrop={handleDroppedFiles}
                />
            </motion.div>
        </motion.div>
    );
};
