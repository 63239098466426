import { Controller } from 'react-hook-form';
import { FormField } from 'modules/ui';

import { DebouncedAutocompleteFieldInner } from './DebouncedAutocompleteFieldInner';

export interface IDebouncedAutocompleteFieldProps<T extends object> {
    options: T[];
    labelProperty: keyof T;
    valueProperty: keyof T;
    name: string;
    label: string;
    isDisabled?: boolean;
    onSelect?: (value: T | null) => void;
    onTyping?: (value: string | null) => void;
    debounceInterval?: number;
}
/**
 *
 * @param options - List of options to be displayed in the autocomplete
 * @param labelProperty - Property of the option object to be displayed as the label
 * @param valueProperty - Property of the option object to be used as the value
 * @param debounceInterval - Interval in milliseconds to debounce the typing event default is 0
 * @returns
 */
export const DebouncedAutocompleteField = <T extends object>(props: IDebouncedAutocompleteFieldProps<T>) => {
    return (
        <Controller
            name={props.name}
            render={({ field, fieldState: { error } }) => (
                <FormField name={props.name}>
                    <DebouncedAutocompleteFieldInner<T>
                        {...props}
                        formValue={field.value}
                        errorMessage={error?.message}
                        fieldOnChange={field.onChange}
                    />
                </FormField>
            )}
        />
    );
};
