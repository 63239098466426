import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { CheckIcon, CopyContentIcon } from 'assets/icons';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import styles from './ReadOnlyInput.module.scss';

export interface IReadOnlyInputProps extends React.ComponentProps<'input'> {
    showCopyButton?: boolean;
    showCopySuccessToast?: boolean;
}

export const ReadOnlyInput = forwardRef<HTMLInputElement, IReadOnlyInputProps>(
    ({ className, disabled, showCopySuccessToast = true, showCopyButton = false, ...props }, ref) => {
        const { t } = useTranslation();
        const localRef = useRef<HTMLInputElement>(null);
        const [copied, setCopied] = useState(false);
        const [copyTimeout, setCopyTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);

        useImperativeHandle(ref, () => localRef.current!, []);

        const handleCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            if (!localRef.current) return;

            localRef.current.select();
            localRef.current.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(localRef.current.value);

            setCopied(true);
            showCopySuccessToast && toast.success(t('common.copiedToClipboard'));

            copyTimeout && clearTimeout(copyTimeout);
            setCopyTimeout(setTimeout(() => setCopied(false), 2000));
        };

        return (
            <div className={styles.inputWrapper}>
                <input ref={localRef} disabled className={classNames(styles.input, className)} {...props} />
                {showCopyButton && (
                    <button onClick={handleCopy} type="button" className={styles.copyButton}>
                        <motion.div key={String(copied)} animate={{ scale: 1 }} initial={{ scale: 0 }}>
                            {copied ? <CheckIcon className={styles.successIcon} /> : <CopyContentIcon />}
                        </motion.div>
                    </button>
                )}
            </div>
        );
    },
);
