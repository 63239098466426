import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { ChevronRightIcon } from 'assets/icons';
import { useDocumentTitle } from 'core/application/hooks';
import { tableNames } from 'modules/common';
import { LocationCustomerSchema } from 'modules/customers';
import { getContainsFilterMeta, getCountryColumnCell, Table, TableContainer, useTableState } from 'modules/table';
import { ITransformedTableLocation, tableSortingFilteringPagination, transformAddressesIncomingData } from 'utils';

import styles from './LocationsTable.module.scss';

const columnHelper = createColumnHelper<ITransformedTableLocation>();

interface ILocationTableProps {
    data: LocationCustomerSchema[];
    setSelectedLocationId: (location_id: number) => void;
}

export const LocationsTable: React.FC<ILocationTableProps> = ({ data, setSelectedLocationId }) => {
    const { t } = useTranslation();
    useDocumentTitle(t('nav.customers'));

    const { tableProps, queryParams, paginationParams } = useTableState<ITransformedTableLocation>({
        rowIdKey: 'location_id',
        getCellStyles: ({ column }) => {
            switch (column.id) {
                case 'company':
                    return [Table.CellTextFormat.SemiBold];
                default:
                    return [];
            }
        },
        onRowClick: (row) => {
            setSelectedLocationId(row.original.location_id);
        },
        tableName: tableNames.customers.locations,
    });

    const changedData = transformAddressesIncomingData(data, t);

    const { result, sortedCount } = tableSortingFilteringPagination({
        data: changedData,
        queryParams,
        paginationParams,
    });

    const columns = useMemo(
        () => [
            columnHelper.accessor('company', {
                header: t('customers.form.locations.company').toLowerCase(),
                cell: (props) => {
                    const value = props.getValue() || '';
                    return value.length > 37 ? `${value.substring(0, 37)}...` : value;
                },
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('countryCode', {
                header: t('customers.form.locations.country').toLowerCase(),
                meta: {
                    align: 'center',
                    ...getContainsFilterMeta(),
                },
                cell: getCountryColumnCell(t),
            }),
            columnHelper.accessor('postalCode', {
                header: t('customers.form.locations.postalCode').toLowerCase(),
                meta: {
                    align: 'right',
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('city', {
                header: t('customers.form.locations.city').toLowerCase(),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('street', {
                header: t('customers.form.locations.street').toLowerCase(),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('routeDirection', {
                header: t('customers.form.locations.routeDirection.label').toLowerCase(),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('contactPerson', {
                header: t('customers.form.locations.contactPerson').toLowerCase(),
                cell: (props) => {
                    return (
                        <div className={styles.contactPersonCell}>
                            <ChevronRightIcon /> {props.getValue()}
                        </div>
                    );
                },
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
        ],
        [t],
    );

    return (
        <div className={styles.tableBox}>
            <TableContainer>
                <Table data={result} columns={columns} totalCount={sortedCount} {...tableProps} />
            </TableContainer>
        </div>
    );
};
