import React, { useCallback,useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLazyGetGeoCodedAddressQuery } from 'core/api';
import { Countries } from 'modules/common';
import { LocationCustomerSchema } from 'modules/customers';
import { DebouncedAutocomplete } from 'modules/ui/components/DebouncedAutocomplete';

export interface ILocationAutocompleteProps {}

export const LocationAutocomplete: React.FC<ILocationAutocompleteProps> = () => {
    const { t } = useTranslation();
    const [getGeoCodedAddress, { data }] = useLazyGetGeoCodedAddressQuery();

    const { setValue } = useFormContext<LocationCustomerSchema>();

    const options = useMemo(() => {
        if (!data) return [];
        return data.map((item) => {
            const { city, country, addressNumber, postCode, street } = item;
            // feature type is address
            if (addressNumber && postCode && street) {
                return {
                    label: `${street} ${addressNumber}, ${city} ${postCode}, ${country}`,
                    ...item,
                };
            }
            // feature type is street
            if (street && postCode) {
                return {
                    label: `${street}, ${city} ${postCode}, ${country}`,
                    ...item,
                };
            }
            // feature type is place
            return {
                label: `${city}, ${country}`,
                ...item,
            };
        });
    }, [data]);

    const [selectedOption, setSelectedOption] = useState<(typeof options)[number] | null>(null);

    const handleTyping = useCallback(async (value: string | null) => {
        if (!value) return;
        await getGeoCodedAddress(value);
    }, [getGeoCodedAddress]);

    const handleSelect = (value: (typeof options)[number] | null) => {
        if (!value) return;
        setSelectedOption(value);
        setValue('city', value.city);
        setValue('street', `${value.street || ''} ${value.addressNumber || ''}`.trim() || '');
        setValue('countryCode', value.countryCode as keyof typeof Countries);
        setValue('postalCode', value.postCode || '');
        setValue('longitude', value.longitude);
        setValue('latitude', value.latitude);
    };

    return (
        <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
            <DebouncedAutocomplete<(typeof options)[number]>
                name="street"
                value={selectedOption}
                label={t('customers.form.locations.addressSearch')}
                labelProperty="label"
                valueProperty="label"
                options={options}
                onTyping={handleTyping}
                onSelect={(data) => data && handleSelect(data)}
                debounceInterval={100}
            />
        </div>
    );
};
