import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Language } from 'core/translation/i18next/i18next';
import { CommissionSchema } from 'modules/commissions';
import { Currency, getCurrencyOptions } from 'modules/common';
import { FormGrid } from 'modules/form/components/FormGrid';
import { FormSection } from 'modules/form/components/FormSection';
import { NumberField } from 'modules/form/components/NumberField';
import { SelectField } from 'modules/form/components/SelectField';
import { TextField } from 'modules/form/components/TextField';
import { Button, Tooltip, Typography } from 'modules/ui';
import { formatPrice } from 'utils/formatPrice';

import styles from './PriceSection.module.scss';

export interface IPriceSectionProps {
    minPriceEstimation?: number;
    maxPriceEstimation?: number;
}

export const PriceSection: React.FC<IPriceSectionProps> = ({ maxPriceEstimation, minPriceEstimation }) => {
    const { t, i18n } = useTranslation();
    const currencyCustomer = useWatch<CommissionSchema>({ name: 'currencyCustomer', defaultValue: Currency.CZK });
    const { setValue } = useFormContext<CommissionSchema>();

    const handleFillPrice = (value?: number) => {
        if (!value) return;

        setValue('priceCustomer', value);
        setValue('currencyCustomer', Currency.CZK);
    };

    return (
        <FormSection title={t('commissions.form.price.sectionTitle')}>
            <FormGrid columns={4}>
                <div className={styles.alignEnd}>
                    <NumberField name="priceCustomer" label={t('commissions.form.customer.price')} />
                </div>
                <div className={styles.alignEnd}>
                    <SelectField
                        options={getCurrencyOptions(t)}
                        name="currencyCustomer"
                        label={t('commissions.form.customer.currency')}
                    />
                </div>
                <div className={styles.priceEstimationContainer}>
                    <Tooltip
                        content={t('commissions.form.price.doubleClickToFillPrice')}
                        disabled={!minPriceEstimation}
                    >
                        <div
                            className={classNames(styles.column, { [styles.clickable]: !!minPriceEstimation })}
                            onDoubleClick={() => handleFillPrice(minPriceEstimation)}
                        >
                            <Typography variant="p" className={styles.title}>
                                {t('commissions.form.price.estimatedPriceMin')}
                            </Typography>
                            <Typography variant="p">
                                {formatPrice(minPriceEstimation, t, {
                                    lang: i18n.language as Language,
                                    roundingMethod: 'ceil',
                                    numberOfDigits: 0,
                                })}
                            </Typography>
                        </div>
                    </Tooltip>
                    <Tooltip
                        content={t('commissions.form.price.doubleClickToFillPrice')}
                        disabled={!maxPriceEstimation}
                    >
                        <div
                            className={classNames(styles.column, { [styles.clickable]: !!maxPriceEstimation })}
                            onDoubleClick={() => handleFillPrice(maxPriceEstimation)}
                        >
                            <Typography variant="p" className={styles.title}>
                                {t('commissions.form.price.estimatedPriceMax')}
                            </Typography>
                            <Typography variant="p">
                                {formatPrice(maxPriceEstimation, t, {
                                    lang: i18n.language as Language,
                                    roundingMethod: 'ceil',
                                    numberOfDigits: 0,
                                })}
                            </Typography>
                        </div>
                    </Tooltip>
                    <div className={styles.column}>
                        <Button variant="ghost" disabled>
                            <Typography variant="p" className={styles.button}>
                                {t('commissions.form.price.recalculate')}
                            </Typography>
                        </Button>
                    </div>
                </div>
                {currencyCustomer === Currency.EUR && (
                    <>
                        <NumberField
                            name="exchangeRateCustomer"
                            label={t('commissions.form.carrier.assignCarrier.exchangeRateCarrier')}
                        />
                        <TextField
                            name="priceCustomerInCZK"
                            label={t('commissions.form.carrier.assignCarrier.priceCarrierInCZK')}
                            disabled
                        />
                    </>
                )}
            </FormGrid>
        </FormSection>
    );
};
