import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { useDocumentTitle } from 'core/application/hooks';
import { useAppSelector } from 'hooks';
import { useGetCarriersQuery } from 'modules/carriers/services';
import { type CarrierSchema } from 'modules/carriers/types';
import { SearchBar, tableNames } from 'modules/common';
import { PageContent, Subheader } from 'modules/layout';
import {
    createSelectColumn,
    formatUnixDate,
    getCountryColumnCell,
    getDateRangeFilterMeta,
    Table,
    TableActionsContainer,
    TableContainer,
    useTableExport,
    useTableState,
} from 'modules/table';
import { getContainsFilterMeta, getDispatchersStatesFilterMeta, getNumberRangeFilterMeta } from 'modules/table';
import { Button, Spinner } from 'modules/ui';
import { getBackstageFetchingList } from 'store/appSlice/selectors';

import { DispatchersOnboardingStates } from '../DispatchersOnboardingStates';

const columnHelper = createColumnHelper<CarrierSchema>();

export const CarriersPage = () => {
    const { t } = useTranslation();
    useDocumentTitle(t('nav.carriers'));

    const navigate = useNavigate();
    const isBackstageFetching = useAppSelector(getBackstageFetchingList).includes('Carriers');
    const { tableProps, queryParams, paginationParams } = useTableState<CarrierSchema>({
        rowIdKey: 'carrier_id',
        onRowClick: (row) => navigate(row.original.carrier_id.toString()),
        tableName: tableNames.carriers.list,
    });
    const { globalFilter, setGlobalFilter, rowSelection, allRowsSelected } = tableProps;
    const {
        handleExport,
        isLoading: isExportLoading,
        isEnabled: isExportEnabled,
    } = useTableExport({
        rowSelection,
        allRowsSelected,
        queryParams,
        endpoint: 'carrierCSV',
        method: 'GET',
        name: 'carriers.csv',
    });

    const {
        data: response,
        isLoading,
        isFetching,
    } = useGetCarriersQuery({
        ...paginationParams,
        ...queryParams,
    });
    const { data, totalRows } = response || {};

    // use location data from the "place" property
    const transformCarrierBasicData = data?.map(({ place, country, postalCode, city, ...item }) => ({
        ...item,
        country: country || place?.country,
        postalCode: postalCode || place?.postalCode,
        city: city || place?.city,
    }));

    const columns = useMemo(
        () => [
            columnHelper.accessor('number', {
                header: t('carriers.fields.number'),
                cell: (info) => (info.getValue() ? info.getValue().toString().padStart(4, '0') : ''),
                meta: {
                    ...getNumberRangeFilterMeta(),
                },
            }),
            columnHelper.accessor('company', {
                header: t('carriers.fields.company'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor(
                (row) => ({
                    registered: row.dispatchersregistered,
                    unregistered: row.dispatchersunregistered,
                    pending: row.dispatcherspending,
                }),
                {
                    header: t('carriers.fields.dispatchersStates'),
                    id: 'dispatchersStates',
                    cell: (info) => {
                        const onboardingStates = info.getValue();
                        return <DispatchersOnboardingStates {...onboardingStates} />;
                    },
                    meta: {
                        ...getDispatchersStatesFilterMeta(),
                    },
                },
            ),
            columnHelper.accessor('countryCode', {
                header: t('carriers.fields.country'),
                meta: {
                    ...getContainsFilterMeta(),
                },
                cell: getCountryColumnCell(t),
            }),
            columnHelper.accessor('postalCode', {
                header: t('carriers.fields.postalCode'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('city', {
                header: t('carriers.fields.city'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('ts_edited', {
                header: t('carriers.fields.ts_edited'),
                cell: (info) => formatUnixDate(info.getValue()),
                meta: {
                    ...getDateRangeFilterMeta(),
                },
            }),
            columnHelper.accessor('note', {
                header: t('carriers.fields.note'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            createSelectColumn(columnHelper),
        ],
        [t],
    );

    if (isLoading) return <Spinner fullScreen />;

    return (
        <PageContent
            fullWidth
            subheader={
                <Subheader
                    title={t('nav.carriers')}
                    endSlot={
                        <TableActionsContainer>
                            {isExportEnabled && (
                                <Button variant="secondary" onClick={handleExport} isLoading={isExportLoading}>
                                    {t('table.actions.export.toCSV')}
                                </Button>
                            )}
                            <Button onClick={() => navigate('new')}>{t('carriers.newCarrier')}</Button>
                        </TableActionsContainer>
                    }
                >
                    <SearchBar value={globalFilter} onChange={setGlobalFilter} />
                </Subheader>
            }
        >
            <TableContainer limit>
                <Table
                    data={transformCarrierBasicData}
                    columns={columns}
                    isLoading={(isLoading || isFetching) && !isBackstageFetching}
                    isFetching={isBackstageFetching}
                    totalCount={Number(totalRows)}
                    getCellStyles={({ column }) => {
                        return column.id === 'number' ? [Table.CellTextFormat.Bold, Table.CellTextColor.Primary] : [];
                    }}
                    {...tableProps}
                />
            </TableContainer>
        </PageContent>
    );
};
