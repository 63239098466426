import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconInfo } from 'assets/icons';
import { useGetInvoicingStatusCountsQuery } from 'modules/invoicing/services';
import { Spinner } from 'modules/ui';
import { FormattedText } from 'modules/ui/components/FormattedText';
import { InfoContainer } from 'modules/ui/components/InfoContainer';

import styles from './InvoicingStatusCountsBanner.module.scss';

export interface IInvoicingStatusCountsBannerProps {}

export const InvoicingStatusCountsBanner: React.FC<IInvoicingStatusCountsBannerProps> = () => {
    const { t } = useTranslation();
    const { isLoading, isError, data, isFetching } = useGetInvoicingStatusCountsQuery();

    return (
        <InfoContainer
            className={styles.info}
            background="blue"
            icon={isFetching ? <Spinner /> : <IconInfo style={{ color: 'inherit' }} />}
        >
            {!isLoading && !isError && data && (
                <FormattedText>
                    {t('invoicing.invoicingStatusCounts', {
                        commissionsForInvoicingCount: data.commissionsForInvoicingCount,
                        unInvoicedCommissionsCount: data.unInvoicedCommissionsCount,
                    })}
                </FormattedText>
            )}
            {isError && t('invoicing.invoicingStatusCountsError')}
        </InfoContainer>
    );
};
