import React from 'react';

export interface IFormattedTextProps {
    children?: string;
}

export const FormattedText: React.FC<IFormattedTextProps> = ({ children }) => {
    if (!children) {
        return null;
    }
    return <span dangerouslySetInnerHTML={{ __html: children }} />;
};
