import React from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { config } from 'config';
import { AttachmentsTable } from 'modules/attachments/components/AttachmentsTable';
import { useListAttachmentsQuery } from 'modules/attachments/services';
import { FormActionButton } from 'modules/form/components/FormActionButton';
import { FormSection } from 'modules/form/components/FormSection';

export interface IAttachmentsSectionProps {
    invoiceId: number;
    invoiceNumber: string;
}

export const AttachmentsSection: React.FC<IAttachmentsSectionProps> = ({ invoiceId, invoiceNumber }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data: attachments, isLoading: isLoadingAttachments } = useListAttachmentsQuery(
        {
            invoiceId,
            includeUploadedBy: true,
            includeCommission: true,
            withCommissionsDeliveryNotes: true,
        },
        { skip: !invoiceId },
    );

    const handleNavigateToCreateAttachment = () => {
        navigate({
            pathname: config.routes.attachments.new,
            search: createSearchParams({
                invoice: JSON.stringify({
                    invoice_id: invoiceId,
                    number: invoiceNumber,
                }),
            }).toString(),
        });
    };

    return (
        <FormSection
            title={t('invoicing.form.attachments.sectionTitle')}
            headerEndSlot={
                <FormActionButton onClick={handleNavigateToCreateAttachment}>
                    {t('attachments.table.addAttachment')}
                </FormActionButton>
            }
        >
            <AttachmentsTable
                invoiceId={invoiceId}
                attachments={attachments || []}
                isLoading={isLoadingAttachments}
                columnsVisibility={{ uploadedBy: false, mimeType: false }}
            />
        </FormSection>
    );
};
