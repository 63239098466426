import { T } from 'core/translation/types';
import { commissionLanguages } from 'modules/commissions';
import { Currency, invoicingLanguages } from 'modules/common';
import z from 'zod';

export type TInvoicingStatusItem = {
    customerId: number;
    customerCompany: string;
    czkCount: number;
    eurCount: number;
};

export type TCommissionForInvoicing = {
    commission_id: number;
    number: number | null;
    vat: string | null;
    orderDate: string | null;
    qid: string | null;
    customer: {
        company: string;
        customer_id: number;
    };
    priceCustomer: number;
    currencyCustomer: Currency;
    exchangeRateCustomer: number;
    commissionloading: {
        date: string;
        location: {
            city: string;
            country: string;
            countryCode: string;
            postalCode: string;
            street: string;
            latitude: string;
            longitude: string;
        };
    }[];
    commissiondischarge: {
        date: string;
        location: {
            city: string;
            country: string;
            countryCode: string;
            postalCode: string;
            street: string;
            latitude: string;
            longitude: string;
        };
    }[];
};

const zodVatCustomValidator = (value: number) => {
    if (value !== 0 && value !== 21) {
        throw new Error('Invalid value. Only 0 and 21 are allowed.');
    }
    return value;
};

const commissionToPdfSchema = () =>
    z.object({
        text: z.string(),
        orderedBy: z.string(),
        orderDate: z.string(),
        loadingDate: z.string(),
        price: z.number(),
        vat: z.number().refine(zodVatCustomValidator),
    });

const invoicePreviewPdfRequestSchema = () =>
    z.object({
        createdBy: z.string(),
        createdByMail: z.string().email(),
        customerCompany: z.string(),
        customerStreet: z.string(),
        customerCountry: z.string(),
        customerPostalCode: z.string(),
        customerCity: z.string(),
        registrationNumber: z.string(),
        currency: z.string(),
        taxId: z.string(),
        language: z.nativeEnum(commissionLanguages),
        rateBase: z.number(),
        varSymbol: z.string(),
        constSymbol: z.string(),
        orderDate: z.string(),
        exposureDate: z.string(),
        maturityDate: z.string(),
        performanceDate: z.string(),
        commissions: z.array(commissionToPdfSchema()),
        paymentMethod: z.string(),
    });

export const uninvoicedCountsSchema = () =>
    z.array(
        z.object({
            customer_company: z.string().nullable(),
            CZK: z.string(),
            EUR: z.string(),
        }),
    );

// commission > commission place
const commissionInvoiceSchema = () =>
    z.object({
        commission_id: z.number(),
        commissionNumber: z.number().optional().nullable(),
        orderDate: z.string(),
        pickupDate: z.number().nullish(),
        pointDate: z.number().nullish(),
        loading_city_string: z.string(),
        discharge_city_string: z.string(),
        vat: z.string(),
        priceCustomer: z.number(),
        currencyCustomer: z.nativeEnum(Currency),
        customer_company: z.string(),
        // properties, not used in the form
        loading_date: z.array(z.number()),
        discharge_date: z.array(z.number()),
        orderSource: z.string().nullish(),
        qid: z.string().optional().nullable(),
    });

// basic
const basicInvoiceDetailSchema = (t: T) =>
    z.object({
        invoice_id: z.number().optional(),
        // status (checkboxes)
        paid: z.boolean(),
        invoiceSent: z.boolean(),
        exported: z.boolean(),
        canceled: z.boolean(),
        // basic data
        invoiceNumber: z.string(),
        customer_company: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .min(1, { message: t('form.errors.required') }),
        currency: z.nativeEnum(Currency, {
            required_error: t('form.errors.required'),
        }).nullable(),
        // dates
        pointDate: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .min(1, { message: t('form.errors.required') }),
        orderDate: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .optional(),
        issueDate: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .min(1, { message: t('form.errors.required') }),
        dueDate: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .min(1, { message: t('form.errors.required') }),
        // order
        constantSymbol: z.string({
            invalid_type_error: t('form.errors.stringFormat'),
        }),
        paymentMethod: z.string(),
        language: z.nativeEnum(invoicingLanguages),
        // properties, not used in the form
        companyRegistrationNumber: z.string().nullable(),
        customer_id: z.number().nullable(),
        deleted: z.boolean().optional(),
        reverseCharge: z.boolean().optional(),
        text: z.string().nullish(),
        totalCommissions: z.number().optional(),
        // totalPrice: z.number().optional(),
        valueAddedTax: z.number().nullable(),
        invoiceAttachmentId: z.number().nullable(),
    });

const attachedFileListSchema = () => z.object({ uri: z.string() });

// basic + (commission > commission place) + customer contact
export const invoiceDetailSchema = (t: T) =>
    basicInvoiceDetailSchema(t).extend({
        // commissions list
        commission: z.array(commissionInvoiceSchema()),
    });

export const invoiceSchema = () =>
    z.object({
        invoice_id: z.number(),
        invoiceNumber: z.string(),
        issueDate: z.string(),
        dueDate: z.string(),
        invoiceSent: z.boolean(),
        exported: z.boolean(),
        deleted: z.boolean(),
        customer_id: z.number(),
        customer_company: z.string(),
        pointDate: z.string(),
        totalPrice: z.number(),
        totalCommissions: z.number(),
        currency: z.string(),
    });

export const invoicesPageSchema = () =>
    z.object({
        data: z.array(invoiceSchema()),
        totalRows: z.number(),
        totalUninvoiced: z.number(),
    });

export type InvoicePreviewPdfRequestSchema = z.infer<ReturnType<typeof invoicePreviewPdfRequestSchema>>;
export type UninvoicedCountsSchema = z.infer<ReturnType<typeof uninvoicedCountsSchema>>;
export type AttachedFileListSchema = z.infer<ReturnType<typeof attachedFileListSchema>>;
export type BasicInvoiceDetailSchema = z.infer<ReturnType<typeof basicInvoiceDetailSchema>>;
export type CommissionInvoiceSchema = z.infer<ReturnType<typeof commissionInvoiceSchema>>;
export type InvoiceDetailSchema = z.infer<ReturnType<typeof invoiceDetailSchema>>;
export type InvoicesPageSchema = z.infer<ReturnType<typeof invoicesPageSchema>>;
export type InvoiceSchema = z.infer<ReturnType<typeof invoiceSchema>>;

export * from './api';
