import { useCallback, useEffect, useRef, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { ChevronDownIcon } from 'assets/icons';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { FormLabel } from 'modules/ui';

import { IDebouncedAutocompleteProps } from './DebouncedAutocomplete';
import { DebouncedAutocompleteOption } from './DebouncedAutocompleteOption';

import styles from './DebouncedAutocomplete.module.scss';

export interface IDebouncedAutocompleteInnerProps<T extends object> extends IDebouncedAutocompleteProps<T> {}
export const DebouncedAutocompleteInner = <T extends object>({
    onTyping,
    label,
    labelProperty,
    name,
    value: currentValue,
    options,
    valueProperty,
    isDisabled,
    onSelect,
    debounceInterval = 0,
}: IDebouncedAutocompleteInnerProps<T>) => {
    const [shownValue, setShownValue] = useState<string>('');
    const [open, setOpen] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const handleShowOptions = () => {
        setOpen(true);
    };
    const handleHideOptions = () => {
        setOpen(false);
    };
    const handleSelectOption = (option: T) => {
        onSelect?.(option);
        setShownValue(String(option[labelProperty]));
        handleHideOptions();
    };

    const handleDebounceTyping = useCallback(
        debounce<(value: string | null) => void>((value) => {
            onTyping?.(value);
        }, debounceInterval),
        [onTyping, debounceInterval],
    );

    const handleInputChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setShownValue(value);
        handleDebounceTyping(value || null);
        handleShowOptions();
    };

    useEffect(() => {
        const option = options.find((option) => option[valueProperty] === currentValue?.[valueProperty]);
        if (!option) {
            return setShownValue('');
        }
        setShownValue(String(option[labelProperty]));
    }, [currentValue]);

    return (
        <Popover.Root
            open={open}
            onOpenChange={(state) => {
                state ? handleShowOptions() : handleHideOptions();
            }}
        >
            <div className={styles['field']}>
                <FormLabel htmlFor={name}>{label}</FormLabel>
                <div className={styles['combo-box']}>
                    <div className={styles['input-wrapper']}>
                        <input
                            id={name}
                            name={name}
                            disabled={Boolean(isDisabled)}
                            placeholder={label}
                            autoComplete="off"
                            value={shownValue}
                            onChange={handleInputChange}
                            onFocus={() => {
                                shownValue.length > 0 && handleShowOptions();
                            }}
                            ref={inputRef}
                            className={classNames(styles.input)}
                        />
                        <Popover.Trigger>
                            <div className={classNames(styles['input-icon'], [{ [styles['input-icon-up']]: open }])}>
                                <ChevronDownIcon />
                            </div>
                        </Popover.Trigger>
                    </div>
                </div>
                <Popover.Anchor style={{ width: '100%' }} />
                <Popover.Portal>
                    <Popover.Content
                        avoidCollisions={false}
                        style={{
                            minWidth: inputRef.current ? `${inputRef.current.offsetWidth}px` : 'unset',
                            zIndex: 10000,
                        }}
                        align="start"
                        onFocusOutside={(e) => {
                            e.preventDefault();
                        }}
                        onOpenAutoFocus={(e) => {
                            e.preventDefault();
                        }}
                        onCloseAutoFocus={(e) => {
                            e.preventDefault();
                        }}
                    >
                        {options.length > 0 && (
                            <ul className={styles['list-box']}>
                                {options.map((option) => (
                                    <DebouncedAutocompleteOption<typeof option>
                                        option={option}
                                        hideOptions={handleHideOptions}
                                        setSelected={handleSelectOption}
                                        labelProperty={labelProperty}
                                        valueProperty={valueProperty}
                                        key={option[valueProperty]?.toString()}
                                    />
                                ))}
                            </ul>
                        )}
                    </Popover.Content>
                </Popover.Portal>
            </div>
        </Popover.Root>
    );
};
