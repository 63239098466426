import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CheckedState } from '@radix-ui/react-checkbox';
import { CellContext, createColumnHelper, Row } from '@tanstack/react-table';
import { config } from 'config';
import { useDocumentTitle } from 'core/application/hooks';
import { useAppSelector } from 'hooks';
import { isNumber } from 'lodash';
import {
    CommissionsTableSchema,
    ContractPdfModal,
    ECommissionState,
    useGetCommissionsQuery,
    useGetCommissionSummaryQuery,
    usePutUpdatedCommissionMutation,
} from 'modules/commissions';
import { tableNames } from 'modules/common';
import { SearchBar } from 'modules/common';
import { PageContent } from 'modules/layout/components/PageContent';
import { Subheader } from 'modules/layout/components/Subheader';
import { OfferStatePill } from 'modules/offers/components/OfferStatePill';
import {
    CellLink,
    createSelectColumn,
    formatPrice,
    getBooleanFilterMeta,
    getContainsFilterMeta,
    getDateRangeFilterMeta,
    getNumberRangeFilterMeta,
    getOfferStateFilterMeta,
    getUserColumnCell,
    Table,
    TableActionsContainer,
    TableContainer,
    useTableExport,
    useTableState,
} from 'modules/table';
import { Button, Checkbox, Spinner, Tooltip, Typography } from 'modules/ui';
import { Pill } from 'modules/ui/components/Pill';
import { getBackstageFetchingList } from 'store/appSlice/selectors';

import { CarrierInvoiceModal } from '../CarrierInvoiceModal';
import { CommissionsSummary } from '../CommissionsSummary';
import { CommissionStateIndicator } from '../CommissionStateIndicator';

import styles from './CommissionPage.module.scss';

const columnHelper = createColumnHelper<CommissionsTableSchema>();

export const CommissionsPage = () => {
    const { t } = useTranslation();
    useDocumentTitle(t('nav.commissions'));

    const navigate = useNavigate();
    const isBackstageFetching = useAppSelector(getBackstageFetchingList).includes('Commissions');

    const [putUpdatedCommission] = usePutUpdatedCommissionMutation();
    const [pendingIds, setPendingIds] = useState<number[]>([]);
    const [pdfModalQid, setPdfModalQid] = useState<[string | null, string | null] | null>(null);
    const [carrierInvoiceId, setCarrierInvoiceId] = useState<number | null>(null);

    const { tableProps, queryParams, paginationParams } = useTableState<CommissionsTableSchema>({
        rowIdKey: 'commission_id',
        onRowClick: (row) => navigate(row.original.commission_id.toString()),
        getCellStyles: ({ column }) => {
            switch (column.id) {
                case 'number':
                    return [Table.CellTextFormat.Bold, Table.CellTextColor.Primary];
                case 'customer_company':
                    return [Table.CellColor.LightGray, Table.CellTextFormat.SemiBold, Table.CellTextColor.White];
                case 'loading_city':
                    return [Table.CellColor.Primary];
                case 'discharge_city':
                    return [Table.CellColor.LightOrange];
                case 'priceCustomer':
                    return [Table.CellColor.Green];
                case 'carrier_company':
                    return [Table.CellTextFormat.SemiBold, Table.CellTextColor.White];
                case 'priceCarrier':
                    return [Table.CellColor.LightGreen];
                case 'provision':
                    return [Table.CellColor.LightYellow];
                default:
                    return [];
            }
        },
        tableName: tableNames.commissions.list,
    });
    const { globalFilter, setGlobalFilter, rowSelection, allRowsSelected } = tableProps;
    const {
        handleExport,
        isLoading: isExportLoading,
        isEnabled: isExportEnabled,
    } = useTableExport({
        rowSelection,
        allRowsSelected,
        queryParams,
        endpoint: 'commissionsCSV',
        method: 'GET',
        name: 'commissions.csv',
    });

    const { data: commissionsSummaryData } = useGetCommissionSummaryQuery({ ...paginationParams, ...queryParams });
    const {
        data: response,
        isLoading,
        isFetching,
    } = useGetCommissionsQuery({
        ...paginationParams,
        ...queryParams,
    });
    const { data, totalRows } = response || {};

    const columns = useMemo(
        () => [
            columnHelper.accessor('state', {
                header: t('commissions.fields.state'),
                cell: (info) => {
                    const commissionState = info.getValue();
                    const { maxPrice, minPrice } = info.row.original.priceEstimation || {};
                    const showTooltip = isNumber(maxPrice) && isNumber(minPrice);
                    return (
                        <Tooltip
                            content={`${t('commissions.form.customer.price')} ${formatPrice(
                                minPrice,
                                0,
                            )} - ${formatPrice(maxPrice, 0)} ${t('common.currency.CZK')}`}
                            disabled={!showTooltip}
                        >
                            <div>
                                <CommissionStateIndicator state={commissionState} />
                            </div>
                        </Tooltip>
                    );
                },
                meta: {
                    align: 'center',
                    ...getBooleanFilterMeta([
                        { value: ECommissionState.Complete, label: t('table.filtering.solved') },
                        { value: ECommissionState.Incomplete, label: t('table.filtering.unsolved') },
                        { value: ECommissionState.Enquiry, label: t('table.filtering.enquiry') },
                    ]),
                },
            }),
            columnHelper.accessor('relation', {
                header: t('commissions.fields.relation'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('week', {
                header: t('commissions.fields.week'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('number', {
                header: t('commissions.fields.number'),
                cell: (info) =>
                    info.getValue() ? (
                        <Tooltip content={t('commissions.form.tooltips.showLastContractPdf')}>
                            <button
                                onClick={() => setPdfModalQid([info.row.original.oldid, info.row.original.qid])}
                                className={styles.numberBtn}
                                type="button"
                            >
                                {info.getValue()}
                            </button>
                        </Tooltip>
                    ) : (
                        ''
                    ),
                meta: {
                    ...getNumberRangeFilterMeta(),
                    disableRowClick: true,
                },
            }),
            columnHelper.accessor('enquiryState', {
                header: t('commissions.fields.enquiryState'),
                cell: (info) => <OfferStatePill state={info.getValue()} enquiryId={info.row.original.enquiry_id} />,
                meta: {
                    disableRowClick: true,
                    ...getOfferStateFilterMeta(),
                },
            }),
            columnHelper.accessor('year', {
                header: t('commissions.fields.year'),
                meta: {
                    ...getNumberRangeFilterMeta(),
                },
            }),
            columnHelper.accessor('customer_company', {
                header: t('commissions.fields.customer_company'),
                cell: (info) => (
                    <CellLink
                        path={`${config.routes.customers.list}/${info.row.original.customer_id}`}
                        title={info.getValue() || ''}
                        bgColor="orange"
                        openInNewTab
                    />
                ),
                meta: {
                    align: 'center',
                    disableRowClick: true,
                    ...getContainsFilterMeta(),
                    getCellContext: (context: CellContext<CommissionsTableSchema, unknown>) => {
                        if (context.row.original.orderConfirmationSent === true) {
                            return {
                                className: styles.orderConfirmationSent,
                            };
                        }
                    },
                },
            }),
            columnHelper.accessor('loading_date', {
                header: t('commissions.fields.loadingDate'),
                cell: (info) => info.getValue(),
                meta: {
                    ...getDateRangeFilterMeta(),
                },
            }),
            columnHelper.accessor('loading_city', {
                header: t('commissions.fields.loadingCity'),
                meta: {
                    ...getContainsFilterMeta(),
                    getCellContext: (context: CellContext<CommissionsTableSchema, unknown>) => {
                        if (context.row.original.loadingConfirmationSent === true) {
                            return {
                                className: styles.loadingConfirmationSent,
                            };
                        }
                    },
                },
            }),
            columnHelper.accessor('loading_zip', {
                header: t('commissions.fields.loadingZip'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('discharge_date', {
                header: t('commissions.fields.dischargeDate'),
                cell: (info) => info.getValue(),
                meta: {
                    ...getDateRangeFilterMeta(),
                },
            }),
            columnHelper.accessor('discharge_city', {
                header: t('commissions.fields.dischargeCity'),
                meta: {
                    ...getContainsFilterMeta(),
                    getCellContext: (context: CellContext<CommissionsTableSchema, unknown>) => {
                        if (context.row.original.dischargeConfirmationSent === true) {
                            return {
                                className: styles.dischargeConfirmationSent,
                            };
                        }
                    },
                },
            }),
            columnHelper.accessor('discharge_zip', {
                header: t('commissions.fields.dischargeZip'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('total_weight', {
                cell: (info) => formatPrice(Number(info.getValue())),
                header: t('commissions.fields.totalWeight'),
                meta: { align: 'right', ...getNumberRangeFilterMeta() },
            }),
            columnHelper.accessor('total_loading_meters', {
                header: t('commissions.fields.totalLoadingMeters'),
                meta: { align: 'right', ...getContainsFilterMeta() },
            }),
            columnHelper.accessor('priceCustomer', {
                cell: (info) => formatPrice(Math.ceil(Number(info.getValue())), 0),
                header: t('commissions.fields.priceCustomer'),
                meta: { align: 'right', ...getNumberRangeFilterMeta() },
            }),
            columnHelper.accessor('invNumber', {
                header: t('commissions.fields.invNumber'),
                cell: (info) => (
                    <CellLink
                        path={`${config.routes.invoicing.list}/${info.row.original.invoice_id}`}
                        title={info.getValue() as unknown as string}
                        openInNewTab
                    />
                ),
                meta: {
                    align: 'center',
                    disableRowClick: true,
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('carrier_company', {
                header: t('commissions.fields.carrierCompany'),
                cell: (info) => (
                    <CellLink
                        path={`${config.routes.carriers.list}/${info.row.original.carrier_id}`}
                        title={info.getValue() || ''}
                        bgColor="blue"
                        openInNewTab
                    />
                ),
                meta: {
                    align: 'center',
                    disableRowClick: true,
                    ...getContainsFilterMeta(),
                    getCellContext: (context: CellContext<CommissionsTableSchema, unknown>) => {
                        if (context.row.original.carrierOrderSent === true) {
                            return {
                                className: styles.carrierOrderSent,
                            };
                        }
                    },
                },
            }),
            columnHelper.accessor('priceCarrier', {
                header: t('commissions.fields.priceCarrier'),
                cell: (info) => formatPrice(Math.ceil(Number(info.getValue())), 0),
                meta: { align: 'right', ...getNumberRangeFilterMeta() },
            }),
            columnHelper.accessor('carrierInvoiceName', {
                header: t('commissions.fields.carrierInvoiceName'),
                cell: (info) => {
                    const value = info.getValue();
                    if (!value) return null;
                    const handleClick = () => setCarrierInvoiceId(info.row.original.attachment_id);
                    return (
                        <Tooltip content={t('commissions.form.tooltips.showReceivedInvoice')}>
                            <div>
                                <Pill color="#0097f5" className={styles.carrierInvoiceNamePill} onClick={handleClick}>
                                    <Typography variant="p" color="white" className={styles.carrierInvoiceName}>
                                        {value}
                                    </Typography>
                                </Pill>
                            </div>
                        </Tooltip>
                    );
                },
                meta: { align: 'right', disableRowClick: true, ...getContainsFilterMeta() },
            }),
            columnHelper.accessor('provision', {
                header: t('commissions.fields.provision'),
                meta: { align: 'right', ...getNumberRangeFilterMeta() },
            }),
            columnHelper.accessor('addedBy', {
                header: t('commissions.fields.addedBy'),
                cell: getUserColumnCell(),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('carrierAssignedBy', {
                header: t('commissions.fields.carrierAssignedBy'),
                cell: getUserColumnCell(),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('notification', {
                header: t('commissions.fields.notification'),
                cell: (info) => {
                    return pendingIds.includes(info.row.original.commission_id) ? (
                        <Spinner fullScreen />
                    ) : (
                        <Checkbox
                            checked={info.getValue()}
                            onCheckedChange={(value) =>
                                handleNotificationChecking(info.row.original.commission_id, value)
                            }
                            disabled={pendingIds.length > 0}
                        />
                    );
                },
                meta: {
                    align: 'center',
                    ...getBooleanFilterMeta([
                        { value: 'true', label: t('table.filtering.true') },
                        { value: 'false', label: t('table.filtering.false') },
                    ]),
                    disableRowClick: true,
                },
            }),
            columnHelper.accessor('note', {
                header: t('commissions.fields.note'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            createSelectColumn(columnHelper),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [t, pendingIds.length],
    );

    const handleNotificationChecking = async (id: number, value: CheckedState) => {
        if (typeof value !== 'boolean') return;
        setPendingIds((prev) => [...prev, id]);
        try {
            const data = await putUpdatedCommission({
                data: { notification: value, commissionLoadings: {}, commissionDischarges: {}, commissionItems: {} },
                id,
            }).unwrap();
            // if commission updated, refetch the new table
            const getRefetched = async () => {
                setPendingIds((prev) => {
                    return prev.filter((id) => id !== data.commission_id);
                });
            };
            getRefetched();
        } catch (error) {
            console.warn(error);
        }
    };

    if (isLoading) return <Spinner fullScreen />;

    return (
        <PageContent
            fullWidth
            subheader={
                <Subheader
                    title={t('nav.commissions')}
                    endSlot={
                        <TableActionsContainer>
                            {isExportEnabled && (
                                <Button onClick={handleExport} variant="secondary" isLoading={isExportLoading}>
                                    {t('table.actions.export.toCSV')}
                                </Button>
                            )}
                            <Button onClick={() => navigate(config.routes.commissions.new)}>
                                {t('commissions.newCommission')}
                            </Button>
                        </TableActionsContainer>
                    }
                >
                    <SearchBar value={globalFilter} onChange={setGlobalFilter} />
                </Subheader>
            }
        >
            <TableContainer limit>
                <Table
                    data={data}
                    columns={columns}
                    totalCount={Number(totalRows)}
                    isLoading={(isLoading || isFetching) && !isBackstageFetching}
                    isFetching={isBackstageFetching}
                    bottomSection={<CommissionsSummary data={commissionsSummaryData} />}
                    getRowStyles={(row: Row<CommissionsTableSchema>) => {
                        if (row.original.state === ECommissionState.Enquiry) return [styles.enquiryCommissionStateRow];
                        return [];
                    }}
                    {...tableProps}
                />
            </TableContainer>
            {pdfModalQid && <ContractPdfModal qid={pdfModalQid} onClose={() => setPdfModalQid(null)} />}
            {carrierInvoiceId && (
                <CarrierInvoiceModal attachmentId={carrierInvoiceId} onClose={() => setCarrierInvoiceId(null)} />
            )}
        </PageContent>
    );
};
