import { CellContext } from '@tanstack/react-table';

export const getUserColumnCell = <User extends { name: string; surname: string }>() => {
    return (cell: CellContext<any, User | undefined | null> | undefined) => {
        if (!cell) return '-';
        const value = cell.getValue();
        if (!value) return '-';
        if (value.name === value.surname) {
            return value.name;
        }
        return `${value.name} ${value.surname}`;
    };
};
