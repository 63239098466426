import React, { useEffect } from 'react';
import {
    ATTACHMENTS_TABLE_COLUMNS,
    AttachmentsTableContextProvider,
    useAttachmentsTable,
} from 'modules/attachments/contexts/attachmentsTable';
import { TAttachment } from 'modules/attachments/types';
import { Table } from 'modules/table';

export interface IAttachmentsTableProps {
    tableName?: string;
    attachments: TAttachment[];
    isLoading?: boolean;
    commissionId?: number;
    invoiceId?: number;
    fixedLayout?: boolean;
    hideHeaderActions?: boolean;
    hideInvoiceState?: boolean;
    columnsVisibility?: Partial<Record<(typeof ATTACHMENTS_TABLE_COLUMNS)[number], boolean>>;
}

const AttachmentsTableInner: React.FC<IAttachmentsTableProps> = ({
    commissionId,
    isLoading,
    tableName,
    invoiceId,
    columnsVisibility,
    attachments,
    fixedLayout = false,
    hideHeaderActions = false,
    hideInvoiceState = false,
}) => {
    const {
        setCommissionId,
        setInvoiceId,
        tableState,
        columns,
        setAttachments,
        filteredAttachments,
        setColumnsVisibility,
        setHideHeaderActions,
        setHideInvoiceState,
    } = useAttachmentsTable(tableName);

    useEffect(() => {
        attachments && setAttachments(attachments);
        commissionId && setCommissionId(commissionId);
        invoiceId && setInvoiceId(invoiceId);
        columnsVisibility && setColumnsVisibility(columnsVisibility);
        setHideHeaderActions(hideHeaderActions);
        setHideInvoiceState(hideInvoiceState);
    }, [commissionId, invoiceId, attachments, columnsVisibility, hideHeaderActions, hideInvoiceState]);

    return (
        <Table
            {...{ columns }}
            data={filteredAttachments}
            totalCount={filteredAttachments.length}
            isLoading={isLoading}
            bordered
            hideBorders
            hidePaginationPanel
            storeColumnWidth={fixedLayout}
            hideResizing={!fixedLayout}
            {...tableState.tableProps}
        />
    );
};

export const AttachmentsTable: React.FC<IAttachmentsTableProps> = (props) => {
    return (
        <AttachmentsTableContextProvider>
            <AttachmentsTableInner {...props} />
        </AttachmentsTableContextProvider>
    );
};
