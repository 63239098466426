import { IconInfo } from 'assets/icons';
import classNames from 'classnames';
import { Typography } from 'modules/ui';

import styles from './InfoContainer.module.scss';

export interface IInfoContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    icon?: React.ReactNode;
    background?: 'gray' | 'blue';
}
export const InfoContainer = ({ icon, children, background = 'gray', className, ...props }: IInfoContainerProps) => {
    return (
        <div className={classNames(styles.infoContainer, className, { [styles[background]]: background })} {...props}>
            {icon || <IconInfo color="#738291" />}
            <Typography className={styles.infoText} variant="subheading">
                {children}
            </Typography>
        </div>
    );
};
