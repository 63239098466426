import React, { useCallback, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAttachmentDetailForm } from 'modules/attachments/contexts/attachmentDetailForm';
import { TAttachmentDetailFormSchema } from 'modules/attachments/types';
import { DebouncedAutocompleteField } from 'modules/form/components/DebouncedAutocompleteField';
import { useListInvoiceNumbersQuery } from 'modules/invoicing';

export interface IInvoiceSelectFieldProps {}

export const InvoiceSelectField: React.FC<IInvoiceSelectFieldProps> = () => {
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState<string | null>(null);
    const { data: invoiceNumbersData, isLoading } = useListInvoiceNumbersQuery(
        {
            search: searchTerm || undefined,
        },
        { skip: !searchTerm },
    );
    const { isNew, attachment, initialInvoice } = useAttachmentDetailForm();
    const { commission_id, invoice_id } = useWatch<TAttachmentDetailFormSchema>();

    const shouldShowInvoiceIdField = useMemo(() => {
        if (isNew && !commission_id) {
            return true;
        }
        if (invoice_id) {
            return true;
        }
        return false;
    }, [isNew, commission_id, invoice_id]);

    const invoiceOptions = useMemo(() => {
        const options = (invoiceNumbersData || []).map(({ invoice_id, invoiceNumber }) => ({
            label: invoiceNumber.toString(),
            value: invoice_id,
        }));
        if (attachment?.invoice?.invoiceNumber && attachment?.invoice?.invoice_id) {
            options.push({
                label: attachment.invoice.invoiceNumber.toString(),
                value: attachment.invoice.invoice_id,
            });
        }
        if (initialInvoice) {
            options.push({
                label: initialInvoice.number.toString(),
                value: initialInvoice.invoice_id,
            });
        }
        return options;
    }, [invoiceNumbersData, attachment?.invoice, initialInvoice]);

    const handleTyping = useCallback(
        (value: string | null) => {
            setSearchTerm(value);
        },
        [setSearchTerm],
    );

    if (!shouldShowInvoiceIdField) {
        return null;
    }
    return (
        <DebouncedAutocompleteField
            name="invoice_id"
            isDisabled={isLoading || !!initialInvoice}
            label={t('attachments.form.fields.invoiceId.title')}
            valueProperty="value"
            labelProperty="label"
            options={invoiceOptions}
            onTyping={handleTyping}
            debounceInterval={300}
        />
    );
};
