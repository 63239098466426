import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from 'config';
import { baseQueryWithAuthRefresh } from 'core/api/baseQuery';
import i18next from 'i18next';
import { type QueryParams } from 'types/query-params';

import {
    CustomerDetailSchema,
    customerDetailSchema,
    type CustomerPageSchema,
    customerPageSchema,
    type CustomerSchema,
} from '../types';
import { TransformedCustomerData } from '../utils/FormattingCustomerData/types';
import { t } from 'i18next';

export const customersApi = createApi({
    reducerPath: 'customersApi',
    baseQuery: baseQueryWithAuthRefresh,
    tagTypes: ['Customers', 'CustomerItem', 'Locations'],
    endpoints: (builder) => ({
        getCustomers: builder.query<CustomerPageSchema, QueryParams<CustomerSchema>>({
            providesTags: ['Customers'],
            query: (params) => ({
                url: config.api.endpoints.customers,
                params,
            }),
            transformResponse: (res: CustomerPageSchema) => {
                // used for back compatibility; almost all customers are incorrect
                const validatedSchema = customerPageSchema(t).safeParse(res);
                if (!validatedSchema.success) {
                    console.warn('Validation failed in getCustomers');
                }
                return res;
            },
        }),
        getAllCustomers: builder.query<CustomerPageSchema, { limit: number }>({
            providesTags: ['Customers'],
            query: (params) => ({
                url: config.api.endpoints.customers,
                params,
            }),
            transformResponse: (res: CustomerPageSchema) => {
                // used for back compatibility; almost all customers are incorrect
                const validatedSchema = customerPageSchema(t).safeParse(res);
                if (!validatedSchema.success) {
                    console.warn('Validation failed in getAllCustomers');
                }
                return res;
            },
        }),
        getOneCustomer: builder.query<CustomerDetailSchema, { id: string }>({
            providesTags: (_result, _error, arg) => [{ type: 'CustomerItem', id: arg.id }],
            query: ({ id }) => ({
                url: `${config.api.endpoints.customers}/${id}`,
            }),
            transformResponse: (res: CustomerDetailSchema) => {
                // used for back compatibility; almost all customers are incorrect
                const validatedSchema = customerDetailSchema(i18next.t).safeParse(res);
                if (!validatedSchema.success) {
                    console.warn('Validation failed in getOneCustomer');
                }

                return res;
            },
        }),
        postNewCustomer: builder.mutation({
            query: ({ data }: { data: TransformedCustomerData }) => ({
                url: config.api.endpoints.customers,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (_, error) => (error ? [] : ['Customers']),
        }),
        putUpdatedCustomer: builder.mutation({
            query: ({ data, id }: { data: TransformedCustomerData; id: string }) => ({
                url: `${config.api.endpoints.customers}/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: (_, error, arg) => (error ? [] : ['Customers', { type: 'CustomerItem', id: arg.id }]),
        }),
        deleteCustomer: builder.mutation<TransformedCustomerData, { id: number }>({
            query: ({ id }) => ({
                url: `${config.api.endpoints.customers}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_, error) => (error ? [] : ['Customers']),
        }),
    }),
});

export const {
    useGetCustomersQuery,
    useGetAllCustomersQuery,
    useGetOneCustomerQuery,
    useLazyGetOneCustomerQuery,
    usePostNewCustomerMutation,
    usePutUpdatedCustomerMutation,
    useDeleteCustomerMutation,
} = customersApi;
