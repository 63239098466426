import React from 'react';

import { DebouncedAutocompleteInner } from './DebouncedAutocompleteInner';

export interface IDebouncedAutocompleteProps<T extends object> {
    options: T[];
    labelProperty: keyof T;
    valueProperty: keyof T;
    name: string;
    value: T | null;
    label: string;
    isDisabled?: boolean;
    onSelect?: (value: T | null) => void;
    onTyping?: (value: string | null) => void;
    debounceInterval?: number;
}
/**
 *
 * @param options - List of options to be displayed in the autocomplete
 * @param labelProperty - Property of the option object to be displayed as the label
 * @param valueProperty - Property of the option object to be used as the value
 * @param debounceInterval - Interval in milliseconds to debounce the typing event default is 0
 * @returns
 */
export const DebouncedAutocomplete = <T extends object>(props: IDebouncedAutocompleteProps<T>) => {
    return (
        <DebouncedAutocompleteInner<T>
            {...props}
        />
    );
};
