import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { config } from 'config';
import { tableNames } from 'modules/common';
import { CommissionInvoiceSchema } from 'modules/invoicing';
import {
    createSelectColumn,
    getContainsFilterMeta,
    getDateRangeFilterMeta,
    getNumberRangeFilterMeta,
    Table,
    TableContainer,
    useTableState,
} from 'modules/table';
import { RowSelection } from 'modules/table/types';
import { Button, Typography } from 'modules/ui';
import { tableSortingFilteringPagination } from 'utils';
import { timestamp } from 'utils/formatTimestamp';

import styles from './InvoiceCommissionTablePopup.module.scss';

type IInvoiceCommissionTableItem = {
    commission_id: number;
    commissionNumber: number;
    pickupDate: number;
    pointDate: number;
    customer_company: string;
    loading_city_string: string;
    discharge_city_string: string;
    priceCustomer: number;
};

const columnHelper = createColumnHelper<IInvoiceCommissionTableItem>();

export interface IInvoiceCommissionTablePopupProps {
    checked: number[];
    availableCommissions: CommissionInvoiceSchema[];
    onCancel: () => void;
    onApprove: (newCommissionsList: CommissionInvoiceSchema[]) => void;
}

// popup to show available commissions
export const InvoiceCommissionTablePopup: React.FC<IInvoiceCommissionTablePopupProps> = ({
    checked,
    availableCommissions,
    onCancel,
    onApprove,
}) => {
    const { t } = useTranslation();

    const { tableProps, queryParams, paginationParams } = useTableState<IInvoiceCommissionTableItem>({
        rowIdKey: 'commission_id',
        defaultPageSize: 10,
        getCellStyles: ({ column }) => {
            const cellStyles: string[] = [];
            if (column.id === 'commissionNumber') {
                cellStyles.push(Table.CellTextColor.Primary, Table.CellTextFormat.SemiBold);
            }
            return cellStyles;
        },
        tableName: tableNames.invoicing.commissions,
    });

    const { rowSelection, setRowSelection } = tableProps;

    useEffect(() => {
        const transformedChecked: RowSelection = {};
        checked.forEach((id) => (transformedChecked[id] = true));
        setRowSelection(transformedChecked); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = useMemo(
        () => [
            columnHelper.accessor('commissionNumber', {
                header: t('invoicing.form.commissions.commissionNumber'),
                cell: (info) => (
                    <button
                        className={styles.commissionNumberCell}
                        onClick={() => {
                            window.open(
                                `${config.routes.commissions.list}/${info.row.original.commission_id}`,
                                '_blank',
                            );
                        }}
                    >
                        {info.getValue()}
                    </button>
                ),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('pickupDate', {
                header: t('invoicing.form.commissions.pickupDate'),
                cell: (info) => timestamp(info.getValue() || 0)?.format('DD. MM. YYYY'),
                meta: {
                    align: 'center',
                    ...getDateRangeFilterMeta(),
                },
            }),
            columnHelper.accessor('pointDate', {
                header: t('invoicing.form.commissions.pointDate'),
                cell: (info) => timestamp(info.getValue() || 0)?.format('DD. MM. YYYY'),
                meta: {
                    align: 'center',
                    ...getDateRangeFilterMeta(),
                },
            }),
            columnHelper.accessor('customer_company', {
                header: t('invoicing.form.commissions.customer'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('loading_city_string', {
                header: t('invoicing.form.commissions.loading_city_string'),
                meta: {
                    align: 'left',
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('discharge_city_string', {
                header: t('invoicing.form.commissions.discharge_city_string'),
                meta: {
                    align: 'left',
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('priceCustomer', {
                header: t('invoicing.form.commissions.priceCustomer'),
                meta: { align: 'right', ...getNumberRangeFilterMeta() },
            }),
            createSelectColumn(columnHelper, undefined, t('invoicing.form.commissions.selection')),
        ],
        [t],
    );

    const { result, sortedCount } = tableSortingFilteringPagination<IInvoiceCommissionTableItem>({
        data: availableCommissions.sort((a, b) => (b.commissionNumber || 0) - (a.commissionNumber || 0)), // Sort by commissionNumber in descending order
        queryParams,
        paginationParams,
        treatAsDate: ['pickupDate', 'pointDate'],
    });

    const onChangesApproved = () => {
        const newCommissionsList = availableCommissions.filter((commission) => {
            return rowSelection[commission.commission_id];
        });

        onApprove(newCommissionsList);
        onCancel();
    };

    return (
        <div className={styles.previewWrapper} onClick={onCancel}>
            <div className={styles.container} onClick={(e) => e.stopPropagation()}>
                <Typography variant="h4">{t('invoicing.form.commissions.preview.title')}</Typography>

                <TableContainer className={styles.tableContainer} limit>
                    <Table data={result} columns={columns} totalCount={sortedCount} {...tableProps} />
                </TableContainer>

                <div className={styles.actionButtons}>
                    <Button onClick={onCancel} variant="secondary">
                        {t('invoicing.form.commissions.preview.cancelChanges')}
                    </Button>
                    <Button onClick={onChangesApproved} variant="primary">
                        {t('invoicing.form.commissions.preview.approveChanges')}
                    </Button>
                </div>
            </div>
        </div>
    );
};
